import { Component, OnInit, ViewChild, ElementRef, Inject, Renderer2 } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSnackBar, MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { ApiService } from 'src/app/services/api.service';
import { NgProgress } from 'ngx-progressbar';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

const BASE_URL = environment.apiUrl;
const BASE_URL_IMAGE = environment.apiImageUrl;
export interface DialogData {
  name: any;
  lastname: any;
  post: any;
  email: any;
  phone: number;
  comments: String;
}
@Component({
  selector: 'app-sales-detail',
  templateUrl: './sales-detail.component.html',
  styleUrls: ['./sales-detail.component.sass']
})
export class SalesDetailComponent implements OnInit {
  idPost;
  dataSource;
  postName: any;
  Sale: any;

  SinglePost: any;
  currentPost: any;
  price: any;
  Solicitude: any;
  hasSales: boolean;
  @ViewChild('block') blocker: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[];
  constructor(
    private _api: ApiService,
    private snack: MatSnackBar,
    private progress: NgProgress,
    private renderer: Renderer2,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.route.params.subscribe((param) => {
      this.idPost = +param['id'];
      this.displayedColumns = ['User', 'Phone', 'Email', 'Action'];
      this._api.showAllSolicitudes(this.idPost).subscribe((data) => {
        this.Sale = data;
        this.dataSource = new MatTableDataSource(this.Sale);
        this.dataSource.paginator = this.paginator;
        if (this.Sale.length > 0) {
          this.hasSales = true;
          this.postName = this.Sale[0].post.name;
        } else {
          this.hasSales = false;
        }
        this._api.getPostWithoutCategory(this.idPost).subscribe((done) => {
          this.SinglePost = done;
          this.currentPost = BASE_URL_IMAGE + this.SinglePost.attachments[0].file.thumb.url;
          this.price = this.SinglePost.price;
          this.spinner.hide();
        });
      }, (err) => { });
    });
  }

  show(element) {
    this._api.showSaleSolicitude(element.post.id, element.id).subscribe((data) => {
      this.Solicitude = data;
      const dialogRef = this.dialog.open(DialogShowCommentsComponent, {
        minWidth: '400px',
        minHeight: '150px',
        data: {
          phone: element.phone,
          name: element.first_name,
          lastname: element.last_name,
          email: element.email,
          comments: element.coments,
          post: element.post
        }
      });
    }, (err) => {
      this.snack.open('Error ' + err.statusText, 'OK', { duration: 4000 });
    });
  }

  delete(element) {
    const dialogRef = this.dialog.open(DialogConfirmSaleComponent, {
      minWidth: '400px',
      minHeight: '400px',
      data: {
        phone: element.phone,
        name: element.first_name,
        lastname: element.last_name,
        email: element.email,
        comments: element.coments,
        post: element.post
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.progress.start();
        this.renderer.addClass(this.blocker.nativeElement, 'active');
        this._api.deleteSaleSolicitude(element.post.id, element.id).subscribe((data) => {
          this.progress.set(0.5);
          this._api.showAllSolicitudes(this.idPost).subscribe((data2) => {
            this.Sale = data2;
            this.dataSource = new MatTableDataSource(this.Sale);
            this.dataSource.paginator = this.paginator;
            if (this.Sale.length > 0) {
              this.hasSales = true;
            } else {
              this.hasSales = false;
            }
            this.renderer.removeClass(this.blocker.nativeElement, 'active');
            this.progress.done();
          }, (err) => {
            this.snack.open(err.statusText, 'OK', { duration: 4000 });
            this.progress.done();
          });
        }, (err) => {
          this.snack.open('Error ' + err.statusText, 'OK', { duration: 4000 });
          this.progress.done();
        });
      }
    });


  }

}




@Component({
  selector: 'app-dialog-comments',
  templateUrl: 'dialog-show.html',
  styleUrls: ['./sales-detail.component.sass']
})
export class DialogShowCommentsComponent {
  comments: any;
  hasComments: boolean;
  name: any;
  lastname: any;
  email: any;
  postrequested: any;
  phone: any;
  constructor(
    public dialogRef: MatDialogRef<DialogShowCommentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {

    this.name = data.name;
    this.lastname = data.lastname;
    this.email = data.email;
    this.phone = data.phone;
    this.postrequested = data.post.name;
    this.comments = data.comments;
    if (data.comments && data.comments !== null) {
      if (data.comments.length > 0) {
        this.comments = data.comments;
        this.hasComments = true;
      } else {
        this.hasComments = false;
      }
    } else {
      this.hasComments = false;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClick() {
    this.dialogRef.close({});
  }
}


@Component({
  selector: 'app-dialog-confirm',
  templateUrl: 'dialog-confirm.html',
  styleUrls: ['./sales-detail.component.sass']
})
export class DialogConfirmSaleComponent {

  name: any;
  lastname: any;
  email: any;
  phone: any;
  comments: any;
  postrequested: any;
  hasComments: boolean;
  constructor(
    public dialogRef: MatDialogRef<DialogConfirmSaleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {

    this.name = data.name;
    this.lastname = data.lastname;
    this.email = data.email;
    this.phone = data.phone;
    this.postrequested = data.post.name;
    this.comments = data.comments;
    if (this.comments && this.comments !== null) {
      if (this.comments.length > 0) {
        this.hasComments = true;
      } else {
        this.hasComments = false;
      }
    } else {
      this.hasComments = false;
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onClick() {
    this.dialogRef.close(true);
  }
}


