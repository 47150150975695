import { Component, OnInit, ViewChild, ElementRef, Renderer2, Inject } from '@angular/core';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../services/api.service';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Lightbox } from 'ngx-lightbox';
import Drift from 'drift-zoom';
const BASE_URL_IMAGE = environment.apiImageUrl;
@Component({
  selector: 'app-publication-detail',
  templateUrl: './publication-detail.component.html',
  styleUrls: ['./publication-detail.component.sass']
})
export class PublicationDetailComponent implements OnInit {

  faBars = faBars;
  faTimes = faTimes;
  hasLinks: boolean;

  BASE_URL_IMAGE = BASE_URL_IMAGE;

  opaque: boolean;
  id: any;
  pubName: any;
  pubDesc: any;

  _album: Array<any> = [];
  _album2 = new Array();
  Links: any;
  attachments: any;

  Publication: any;
  @ViewChild('list') list: ElementRef;
  @ViewChild('sidenav') sidenav: ElementRef;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _api: ApiService,
    private renderer: Renderer2,
    private _lightbox: Lightbox,
    private spinner: NgxSpinnerService,
    public translate: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {
  }
  onNavigate(url) {
    if (url.startsWith('http') || url.startsWith('https')) {
      window.open(url, '_blank');
    } else {
      const newUrl = 'https://' + url;
      console.log(newUrl);
      window.open(newUrl, '_blank');
    }
  }

  ngOnInit() {
    this.document.body.classList.remove('noscroll');
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.opaque = false;
    this.spinner.show();
    this.document.body.classList.add('noscroll');
    this.route.params.subscribe((param) => {
      this.id = +param['id'];
      this._api.getPublicationAsGuest(this.id).subscribe((pub) => {
        this.Publication = pub[0];
        this.pubDesc = this.Publication.description;
        this.pubName = this.Publication.name;
        if (this.Publication.links && this.Publication.links[0].length !== 0) {
          this.Links = this.Publication.links[0].split(',');
          this.hasLinks = true;
        } else {
          this.Links = [];
          this.hasLinks = false;
        }
        this.attachments = this.Publication.attachments;

        this.attachments.forEach(element => {
          const album = {
            src: BASE_URL_IMAGE + element.file.hd.url,
            thumb: BASE_URL_IMAGE + element.file.thumb.url,
          };
          this._album2.push(BASE_URL_IMAGE + element.file.thumb.url);
          this._album.push(album);
        });
        // tslint:disable-next-line:no-unused-expression
        this.spinner.hide();
        this.document.body.classList.remove('noscroll');
      });

    });
  }
  gotomedia() {
    this.router.navigateByUrl('publication-media/' + this.id);
  }
  open(index: number): void {
    this._lightbox.open(this._album, index, {
      showImageNumberLabel: true,
      alwaysShowNavOnTouchDevices: true,
      disableScrolling: true,
      centerVertically: true
    });
  }
  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  translatePage(event) {
    if (event.target.innerHTML === 'SPANISH') {
      this.translate.use('es');
    } else {
      this.translate.use('en');
    }
  }


  openSideNav() {
    this.renderer.addClass(this.sidenav.nativeElement, 'open');
    this.renderer.removeClass(this.list.nativeElement, 'paused');
    this.document.body.classList.add('noscroll');
  }
  closeSideNav() {
    this.renderer.removeClass(this.sidenav.nativeElement, 'open');
    this.document.body.classList.remove('noscroll');
    this.list.nativeElement.forEach(element => {
      element.classList.remove('appear');
    });
    this.renderer.addClass(this.list.nativeElement, 'paused');
    this.list.nativeElement.forEach(element => {
      element.classList.add('appear');
    });
  }

}
