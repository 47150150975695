import { Component, OnInit, OnDestroy, Inject, HostListener, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { NgProgress } from 'ngx-progressbar';
import { environment } from '../../../../environments/environment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatSnackBar } from '@angular/material';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
const BASE_URL = environment.apiUrl;
const BASE_URL_IMAGE = environment.apiImageUrl;


export interface DialogData2 {
  categoryName: string;
  categoryDesc: string;
  selectedFiles: any[];
  attachments: any;
  id: any;
  thumb: any;
}
export interface DialogData3 {
  pictureName: string;
  picturePrice: any;
  pictureBrief: any;
  pictureDesc: any;
  attachements: any;
  id: any;
  cate: any;
  pictureFile: any[];
  thumb: any;
  status: boolean;
}
@Component({
  selector: 'app-categories-detail',
  templateUrl: './categories-detail.component.html',
  styleUrls: ['./categories-detail.component.sass']
})
export class CategoriesDetailComponent implements OnInit, OnDestroy {

  id: Number;
  private sub: any;
  Category: any;
  showingPost: boolean;
  totalPosts;
  baseUrl;
  thumb;



  @ViewChild('arrowScroll') arrowScroll: ElementRef;
  @ViewChild('block') blocker: ElementRef;
  // Category Info.
  categoryURL: any;
  categoryName: any;
  categoryDesc: any;

  // Posts Info.
  Posts: any;
  postimg: any;
  postName: any;
  postDesc: any;

  constructor(private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private _api: ApiService,
    public dialog: MatDialog,
    public progress: NgProgress,
    private renderer: Renderer2,
    private snack: MatSnackBar,
    private router: Router) { }

  ngOnInit() {
    this.baseUrl = BASE_URL;
    this.showingPost = false;
    this.renderCategory();
  }

  getUrl(post) {
    if (post.attachments[0] !== undefined && post.attachments[0] !== null && post.attachments[0].length > 0) {
      return this.baseUrl + post.attachments[0].file.thumb.url;
    } else {
      if (post.thumb) {
        return post.thumb;
      } else {
        return 'assets/images/question-mar.png';
      }
    }
  }

  renderCategory() {
    this.spinner.show();
    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id'];
      this._api.getCategory(this.id).subscribe((data) => {
        this.Category = data;
        if (this.Category['attachments'].length !== 0 && this.Category['attachments']) {
          if (this.Category['attachments'][0].file.thumb.url !== undefined && this.Category['attachments'][0].file.thumb.url) {
            this.categoryURL = this.baseUrl + this.Category['attachments'][0].file.thumb.url;
          } else {
            this.categoryURL = 'assets/images/question-mar.png';
          }
        } else {
          this.categoryURL = 'assets/images/question-mar.png';
        }

        this.categoryName = this.Category.name;
        this.categoryDesc = this.Category.description;
        setTimeout(() => {
          this.spinner.hide();
        }, 300);
      }, (err) => { });
    });

  }

  showPosts() {
    this.spinner.show();
    this.showingPost = true;
    this._api.getAllPosts(this.id).subscribe((data) => {
      this.totalPosts = JSON.parse(JSON.stringify(data)).length;
      this.Posts = JSON.parse(JSON.stringify(data));
      this.Posts.forEach(element => {
        if (element['attachments'][0].file.thumb.url !== undefined && element['attachments'][0].file.thumb.url) {
          element['thumb'] = this.baseUrl + element['attachments'][0].file.thumb.url;
        } else {
          element['thumb'] = 'assets/images/question-mar.png';
        }
      });
      this.spinner.hide();
      setTimeout(() => {
        const elem = document.getElementById('contScroll');
        const arrow = document.getElementById('arrowScroll');
        if (elem.clientHeight === 450) {
          arrow.classList.add('canScroll');
        }
      }, 300);
    });
  }

  showPostDetail(postid) {
    this.router.navigateByUrl('/admin/panel/post-detail/' + this.id + '/' + postid);
  }

  back() {
    this.showingPost = false;
    const arrow = document.getElementById('arrowScroll');
    arrow.classList.remove('canScroll');
  }
  ngOnDestroy() {
    if (this.sub !== undefined || this.sub !== null) {
      this.sub.unsubscribe();
    }

  }



  ifnull(price) {
    if (price !== null && price !== undefined) {
      return price;
    } else {
      return 0;
    }
  }

  showArrow(event) {
    const container = document.getElementById('contScroll');
    const arrow = document.getElementById('arrowScroll');
    if (container.scrollTop === (container.scrollHeight - container.offsetHeight)) {
      arrow.classList.remove('canScroll');
    } else {
      arrow.classList.add('canScroll');
    }
  }

  update(name, description, files, id) {
    this.progress.start();
    this.renderer.addClass(this.blocker.nativeElement, 'active');
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        const element = files[key];
        formData.append('attachments_files[]', element);
      }
    }
    if (files && files.length > 0) {
      this._api.getCategory(this.id).subscribe((data) => {
        const idattachment = data['attachments'][0].id;
        this._api.deleteAttachment(idattachment).subscribe((attch) => {
          this._api.updateCategory(id, formData, response => {
            console.log(response);
            if (response) {
              this._api.getCategory(this.id).subscribe((data3) => {
                this.Category = data3;
                if (this.Category['attachments'].length !== 0 && this.Category['attachments']) {
                  if (this.Category['attachments'][0].file.thumb.url !== undefined && this.Category['attachments'][0].file.thumb.url) {
                    this.categoryURL = this.baseUrl + this.Category['attachments'][0].file.thumb.url;
                  } else {
                    this.categoryURL = 'assets/images/question-mar.png';
                  }
                } else {
                  this.categoryURL = 'assets/images/question-mar.png';
                }
                this.renderer.removeClass(this.blocker.nativeElement, 'active');
                this.categoryName = this.Category.name;
                this.categoryDesc = this.Category.description;
                this.progress.done();
                this.snack.open('Succesfully edited', 'OK', {
                  duration: 2000
                });
              }, (err) => {
                this.renderer.removeClass(this.blocker.nativeElement, 'active');
                this.progress.done();
                this.snack.open('An error occurs, try again', 'OK', {
                  duration: 2000
                });
              });
            }
          });
        }, (err) => {
          this.snack.open('An error occurs, try again', 'OK', {
            duration: 2000
          });
          this.renderer.removeClass(this.blocker.nativeElement, 'active');
          this.progress.done();
        });
      }, (error) => {
        console.log(error);
      });
    } else {
      this._api.updateCategory(id, formData, response => {
        if (response) {
          console.log(response);
          this._api.getCategory(this.id).subscribe((data) => {
            this.Category = data;
            if (this.Category['attachments'].length !== 0 && this.Category['attachments']) {
              if (this.Category['attachments'][0].file.thumb.url !== undefined && this.Category['attachments'][0].file.thumb.url) {
                this.categoryURL = this.baseUrl + this.Category['attachments'][0].file.thumb.url;
              } else {
                this.categoryURL = 'assets/images/question-mar.png';
              }
            } else {
              this.categoryURL = 'assets/images/question-mar.png';
            }
            this.snack.open('Sucessfully edited', 'OK', {duration: 5000});
            this.renderer.removeClass(this.blocker.nativeElement, 'active');
            this.categoryName = this.Category.name;
            this.categoryDesc = this.Category.description;
            this.progress.done();
          }, (err) => { });
        }
      });
    }


  }

  // Modal sections
  openModalCreate(id) {

    const dialogRef = this.dialog.open(DialogCreatePostComponent, {
      width: '700px',
      minHeight: '500px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.files !== undefined) {
        this.createPost(result.name, result.description, result.brief, result.price, result.files);
      } else {
        this.snack.open('Empty fields, need to upload at least one picture ', 'OK', { duration: 3000 });
      }
    });

  }

  openModalUpdate(): void {
    this._api.getCategory(this.id).subscribe((data) => {
      let thumbnail;
      if (data['attachments'].length !== 0) {
        if (data['attachments'][0].file.thumb.url !== undefined && data['attachments'][0].file.thumb.url) {
          thumbnail = this.baseUrl + data['attachments'][0].file.thumb.url;
        } else {
          thumbnail = 'assets/images/question-mar.png';
        }
      } else {
        thumbnail = 'assets/images/question-mar.png';
      }
      const dialogRef = this.dialog.open(DialogUpdate2Component, {
        width: '750px',
        minHeight: '500px',
        data: {
          categoryName: data['name'],
          categoryDesc: data['description'],
          selectedFiles: [],
          attachments: data['attachments'],
          id: data['id'],
          thumb: thumbnail
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        this.update(result.name, result.description, result.files, this.id);
      });
    });

  }

  openDialogDelete(postid, status) {
    const dialogRef = this.dialog.open(DialogDeletePostComponent, {
      width: '300px',
      minHeight: '250px',
      data: {
        status: status
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deletePost(postid, status);
      }
    });
  }

  /*  pictureName: string;
  picturePrice: any;
  pictureBrief: any;
  pictureDesc: any;
  pictureFile: any[]; */
  openDialogUpdatePost(post) {
    this._api.getPost(this.id, post.id).subscribe((data) => {
      let thumbnail;
      const Post = JSON.parse(JSON.stringify(data));
      if (Post['attachments'][0].file.thumb.url !== undefined && Post['attachments'][0].file.thumb.url) {
        thumbnail = this.baseUrl + data['attachments'][0].file.thumb.url;
      } else {
        thumbnail = null;
      }
      const dialogRef = this.dialog.open(DialogUpdatePostComponent, {
        width: '700px',
        minHeight: '500px',
        data: {
          pictureName: data['name'],
          picturePrice: data['price'],
          pictureBrief: data['description'],
          pictureDesc: data['body'],
          attachements: data['attachments'],
          id: post.id,
          cate: post.category_id,
          thumb: thumbnail
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.editPost(post.id, result);
        }
      });
    }, (err) => { });
  }
  createPost(name, description, brief, price, files) {
    const formData = new FormData();

    formData.append('name', name);
    formData.append('description', brief);
    formData.append('body', description);
    formData.append('price', price);
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        const element = files[key];
        formData.append('attachments_files[]', element);
      }
    }
    this.renderer.addClass(this.blocker.nativeElement, 'active');
    this.progress.start();
    this.progress.set(0.1);
    this._api.createPost(this.id, formData, response => {
      if (response) {
        this.progress.set(0.2);
        this._api.getAllPosts(this.id).subscribe((data) => {
          this.totalPosts = JSON.parse(JSON.stringify(data)).length;
          this.Posts = JSON.parse(JSON.stringify(data));
          this.Posts.forEach(element => {
            if (element['attachments'][0].file.thumb.url !== undefined && element['attachments'][0].file.thumb.url) {
              element['thumb'] = this.baseUrl + element['attachments'][0].file.thumb.url;
            } else {
              element['thumb'] = 'assets/images/question-mar.png';
            }
          });
          this.renderer.removeClass(this.blocker.nativeElement, 'active');
          this.snack.open('Succesfully created', 'OK', {
            duration: 2000
          });
          this.progress.done();
        });
      } else {
        this.renderer.removeClass(this.blocker.nativeElement, 'active');
        this.snack.open('Error try again', 'OK', {
          duration: 2000
        });
        this.renderer.removeClass(this.blocker.nativeElement, 'active');
        this.progress.done();
      }
    });
  }
  deletePost(postid, status) {
    this.progress.start();
    this.renderer.addClass(this.blocker.nativeElement, 'active');
    this.progress.set(0.2);
    let body;
    if (status) {
      body = {'status': false};
    } else {
      body = {'status': true};
    }
    this._api.disablePost(this.id, postid, body).subscribe((data) => {
      this._api.getAllPosts(this.id).subscribe((posts) => {
        this.totalPosts = JSON.parse(JSON.stringify(posts)).length;
        this.Posts = JSON.parse(JSON.stringify(posts));
        this.Posts.forEach(element => {
          if (element['attachments'][0].file.thumb.url !== undefined && element['attachments'][0].file.thumb.url) {
            element['thumb'] = this.baseUrl + element['attachments'][0].file.thumb.url;
          } else {
            element['thumb'] = 'assets/images/question-mar.png';
          }
        });
        this.renderer.removeClass(this.blocker.nativeElement, 'active');
        this.progress.done();
        if (status) {
          this.snack.open('Succesfully disabled', 'OK', {
            duration: 4000,
          });
        } else {
          this.snack.open('Succesfully enabled', 'OK', {
            duration: 4000,
          });
        }
      });
    }, (err) => {
      this.renderer.removeClass(this.blocker.nativeElement, 'active');
      this.snack.open(err, 'OK', {
        duration: 2000,
      });
    });
  }
  editPost(postid, result) {
    const formData = new FormData();
    this.progress.start();
    this.progress.set(0.1);
    this.renderer.addClass(this.blocker.nativeElement, 'active');
    formData.append('name', result.pictureName);
    formData.append('description', result.pictureBrief);
    formData.append('body', result.pictureDesc);
    formData.append('price', result.picturePrice);
    if (result.pictureImage !== undefined && result.pictureImage.length !== 0) {
      for (const key in result.pictureImage) {
        if (result.pictureImage.hasOwnProperty(key)) {
          const element = result.pictureImage[key];
          formData.append('attachments_files[]', element);
        }
      }
    }
    this._api.updatePost(this.id, postid, formData, response => {
      if (response) {
        this._api.getAllPosts(this.id).subscribe((data) => {
          this.Posts = JSON.parse(JSON.stringify(data));
          this.Posts.forEach(element => {
            if (element['attachments'].length !== 0 && element['attachments']) {
              if (element['attachments'][0].file.thumb.url !== undefined && element['attachments'][0].file.thumb.url) {
                element['thumb'] = this.baseUrl + element['attachments'][0].file.thumb.url;
              } else {
                element['thumb'] = 'assets/images/question-mar.png';
              }
            } else {
              element['thumb'] = 'assets/images/question-mar.png';
            }
          });
          this.renderer.removeClass(this.blocker.nativeElement, 'active');
          this.progress.done();
        }, (err) => {
          this.renderer.removeClass(this.blocker.nativeElement, 'active');
          this.progress.done();
        });
      }
    });
  }
}


@Component({
  selector: 'app-dialog-post',
  templateUrl: 'dialog-create.html',
  styleUrls: ['./categories-detail.component.sass']
})
export class DialogCreatePostComponent {
  files: string;
  pictureName: string;
  pictureBrief: string;
  pictureDesc: string;
  pictureFile: any[];
  categoryThumb: any;
  picturePrice: any;
  constructor(
    public dialogRef: MatDialogRef<DialogCreatePostComponent>
  ) {
    this.files = 'Upload a image';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  // Pass the data from the modal to the component
  onClick() {
    this.dialogRef.close(
      {
        files: this.pictureFile,
        name: this.pictureName,
        brief: this.pictureBrief,
        description: this.pictureDesc,
        price: this.picturePrice
      });
  }
  onFileChanged(event) {
    this.pictureFile = event.target.files;
    this.files = this.pictureFile.length + ' Files Selected';
  }

}


// Modal for delete post.
@Component({
  selector: 'app-dialog-delete',
  templateUrl: 'dialog-confirm.html',
  styleUrls: ['./categories-detail.component.sass']
})
export class DialogDeletePostComponent {
  files: string;
  delete: any;

  messageEnabled: boolean;

  constructor(
    public dialogRef: MatDialogRef<DialogDeletePostComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData3
  ) {
    this.messageEnabled = data.status;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  // Pass the data from the modal to the component
  onClick() {
    this.dialogRef.close(
      {
        delete: true
      });
  }
}


// modal for update category
@Component({
  selector: 'app-dialog-update',
  templateUrl: 'dialog-update.html',
  styleUrls: ['./categories-detail.component.sass']
})
export class DialogUpdate2Component {
  files: string;
  name: any;
  faTimes = faTimes;
  categoryName: string;
  categoryDesc: string;
  thumb: any;
  selectedFiles: any[];
  attachments: any;
  idCat: any;
  BASE_URL_IMAGE = BASE_URL_IMAGE;
  constructor(
    public dialogRef: MatDialogRef<DialogUpdate2Component>,
    private progress: NgProgress,
    private _api: ApiService,
    private snack: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData2
  ) {
    this.categoryName = data.categoryName;
    this.categoryDesc = data.categoryDesc;
    this.selectedFiles = data.selectedFiles;
    this.attachments = data.attachments;
    this.files = 'Change image';
    this.thumb = data.thumb;
    this.idCat = data.id;
  }

  onNoClick() {
    this.dialogRef.close();
  }

  deleteAttachment(item) {
    this.progress.start();
    this._api.deleteAttachment(item.id).subscribe((res) => {
      this._api.getCategory(this.idCat).subscribe((publication) => {
        this.attachments = publication['attachments'];
        this.progress.done();
        this.snack.open('Image deleted', 'DONE', {
          duration: 2000
        });
      });
    }, (err) => {
      this.progress.done();
      this.snack.open('Can not delete', 'DONE', {
        duration: 2000
      });
    });
  }
  onClick() {
    this.dialogRef.close({
      files: this.selectedFiles,
      name: this.categoryName,
      description: this.categoryDesc
    });
  }
  onFileChanged(event) {
    this.selectedFiles = event.target.files;
    console.log(this.selectedFiles);
    this.files = this.selectedFiles.length + ' Files Selected';
  }

}



// Modal for update post
@Component({
  selector: 'app-dialog-update-post',
  templateUrl: 'dialog-post-update.html',
  styleUrls: ['./categories-detail.component.sass']
})



export class DialogUpdatePostComponent {
  files: string;
  pictureName: string;
  pictureBrief: string;
  pictureDesc: string;
  picturePrice: string;
  selectedFiles: any[];
  attachments: any;
  faTimes = faTimes;
  idpost: any;
  idcat: any;

  hasMorePost: boolean;

  BASE_URL_IMAGE = BASE_URL_IMAGE;
  currentThumb = 'assets/images/question-mar.png';
  constructor(
    public dialogRef: MatDialogRef<DialogUpdatePostComponent>,
    private progress: NgProgress,
    private _api: ApiService,
    private snack: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData3
  ) {
    this.pictureName = data.pictureName;
    this.pictureDesc = data.pictureDesc;
    this.pictureBrief = data.pictureBrief;
    this.picturePrice = data.picturePrice;
    this.attachments = data.attachements;
    if (data.thumb !== null) {
      this.currentThumb = data.thumb;
    }
    if (this.attachments.length > 1) {
      this.hasMorePost = true;
    } else {
      this.hasMorePost = false;
    }
    this.idpost = data.id;
    this.idcat = data.cate;
    this.selectedFiles = [];
    this.files = 'Change image';
  }

  deleteAttachment(item) {
    console.log(item.id);
    this.progress.start();
    this._api.deleteAttachment(item.id).subscribe((res) => {
      this._api.getPost(this.idcat, this.idpost).subscribe((publication) => {
        this.attachments = publication['attachments'];
        if (this.attachments.length > 1) {
          this.hasMorePost = true;
        } else {
          this.hasMorePost = false;
        }
        this.progress.done();
        this.snack.open('Image deleted', 'DONE', {
          duration: 2000
        });
      });
    }, (err) => {
      this.progress.done();
      this.snack.open('Can not delete', 'DONE', {
        duration: 2000
      });
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClick() {
    this.dialogRef.close({
      pictureName: this.pictureName,
      pictureBrief: this.pictureBrief,
      pictureDesc: this.pictureDesc,
      picturePrice: this.picturePrice,
      pictureImage: this.selectedFiles,
      currentThumb: this.currentThumb
    });
  }
  onFileChanged(event) {
    this.selectedFiles = event.target.files;
    this.files = this.selectedFiles.length + ' Files Selected';
  }

}

