import { Component, OnInit, HostListener, Renderer2, ViewChild, ElementRef, Inject } from '@angular/core';
import { faBars, faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DOCUMENT } from '@angular/platform-browser';
import { WINDOW } from '../windows.service';
import { environment } from 'src/environments/environment';
import { ApiService } from '../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-gallery-slider-toggle',
  templateUrl: './gallery-slider-toggle.component.html',
  styleUrls: ['./gallery-slider-toggle.component.sass']
})
export class GallerySliderToggleComponent implements OnInit {
  faBars = faBars;
  faChevronDown = faChevronDown;
  faTimes = faTimes;

  opaque: boolean;

  hasMorePost: boolean;

  urlimage = environment.apiImageUrl;
  id: any;
  Atributes: any;
  NameSelected: any;
  urlSelected: any;

  currentPostId: any;
  currentPostName: any;
  currentPostDesc: any;
  currentPostBody: any;

  categoryName: any;
  categoryDesc: any;

  lenghtPost: any;
  idSelected: any;
  Posts: any;

  @ViewChild('sidenav') sidenav: ElementRef;
  @ViewChild('list') list: ElementRef;

  constructor(
    private renderer: Renderer2,
    private _api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    public translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.document.body.classList.remove('noscroll');
    this.opaque = false;
    this.document.body.classList.add('noscroll');
    this.route.params.subscribe((param) => {
      this.id = +param['id'];
      this._api.getCategoryAsGuest(this.id).subscribe((category) => {
        this.categoryName = category['name'];
        this.categoryDesc = category['description'];
        this._api.getAllPostsAsGuest(this.id).subscribe((posts) => {
          this.Posts = posts;
          this.lenghtPost = this.Posts.length;
          if (this.lenghtPost < 4) {
            this.hasMorePost = false;
          } else {
            this.hasMorePost = true;
          }
          setTimeout(() => {
            this.spinner.hide();
            this.document.body.classList.remove('noscroll');
          }, 1000);
        });
      });
    });
  }


  translatePage(event) {
    // console.log(event.target.innerHTML);
    if (event.target.innerHTML === 'SPANISH') {
      this.translate.use('es');
    } else {
      this.translate.use('en');
    }
  }


  switchToSlides() {
    this.router.navigateByUrl('/gallery/slides/' + this.id);
  }


  buy(id) {
    this.router.navigateByUrl('/post/buy/' + id + '/' + this.id );
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const number = this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
    if (number > 100) {
      this.opaque = true;
    } else if (this.opaque && number < 10) {
      this.opaque = false;
    }
  }

  openSideNav() {
    this.renderer.addClass(this.sidenav.nativeElement, 'open');
    this.renderer.removeClass(this.list.nativeElement, 'paused');
    this.document.body.classList.add('noscroll');
  }
  closeSideNav() {
    console.log(this.sidenav.nativeElement);
    this.renderer.removeClass(this.sidenav.nativeElement, 'open');
    this.document.body.classList.remove('noscroll');
    this.list.nativeElement.forEach(element => {
      element.classList.remove('appear');
    });
    this.renderer.addClass(this.list.nativeElement, 'paused');
    this.list.nativeElement.forEach(element => {
      element.classList.add('appear');
    });
  }


}
