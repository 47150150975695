import { Component, OnInit } from '@angular/core';
import { NgProgress } from 'ngx-progressbar';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material';


@Component({
  selector: 'app-biography-control',
  templateUrl: './biography-control.component.html',
  styleUrls: ['./biography-control.component.sass']
})
export class BiographyControlComponent implements OnInit {


  content: any;
  editMode: boolean;
  editorContent: any;
  constructor(
    private progress: NgProgress,
    private spinner: NgxSpinnerService,
    private _api: ApiService,
    private snack: MatSnackBar
  ) { }

  ngOnInit() {
    this.editMode = false;
    this.renderHTML();
  }

  renderHTML() {
    this.progress.start();
    this._api.getRenderBiographyAsAdmin().subscribe((doc) => {
      this.content = doc;
      this.content = this.content.body;
      this.editorContent = this.content;
      this.progress.done();
    }, (err) => {
      this.snack.open('Error open editor mode ', 'OK', { duration: 4000 });
      this.progress.done();
    });
  }



  switchEdit() {
    if (this.editMode === false) {
      this.editMode = true;
    } else {
      this.progress.start();
      const body = {
        'body': this.editorContent
      };
      this._api.editBiographyAsAdmin(body).subscribe((done) => {
        this.progress.done();
        this.content = done['body'];
        this.snack.open('Edited the biography successfully', 'OK', { duration: 4000 });
        this.editMode = false;
      }, (err) => {
        this.progress.done();
        this.snack.open('Error while editing the biography', 'OK', { duration: 4000 });
        this.editMode = false;

      });
    }
  }

}
