import { Component, OnInit, Inject, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ApiService } from 'src/app/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgProgress } from 'ngx-progressbar';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/platform-browser';

const BASE_URL_IMAGE = environment.apiImageUrl;

export interface DialogData {
  urlimage: any;
}
export interface DialogData2 {
  attachments: any;
  pubName: any;
  pubDesc: any;
  id: any;
  Links: any;
}
@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.sass']
})
export class BlogDetailComponent implements OnInit {
  faTimes = faTimes;
  id: any;
  Publication: any;
  Links: any;
  pubName: any;
  pubDesc: any;
  attachments: any;
  hasMoreImages: boolean;
  @ViewChild('block') blocker: ElementRef;
  BASE_URL_IMAGE = BASE_URL_IMAGE;

  constructor(
    private _api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private progress: NgProgress,
    private render: Renderer2,
    private snack: MatSnackBar,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.route.params.subscribe((param) => {
      this.id = +param['id'];
      this._api.getPublication(this.id).subscribe((pub) => {
        this.Publication = pub;
        this.pubName = this.Publication.name;
        this.pubDesc = this.Publication.description;
        this.attachments = this.Publication.attachments;
        if (this.attachments.length > 1) {
          this.hasMoreImages = true;
        } else {
          this.hasMoreImages = false;
        }
        this.Links = this.Publication.links;
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        this.snack.open('Error can not load publication', 'OK', {
          duration: 4000
        });
      });
    });
  }

  openDialogUpdate() {
    const dialogRef = this.dialog.open(DialogUpdateBlogDComponent, {
      width: '700px',
      minHeight: '500px',
      data: {
        id: this.id,
        pubName: this.pubName,
        pubDesc: this.pubDesc,
        attachments: this.attachments,
        Links: this.Links
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.render.addClass(this.blocker.nativeElement, 'active');
        this.progress.start();
        const formData = new FormData();
        formData.append('name', result.name);
        formData.append('description', result.description);
        formData.append('links[]', result.links);
        for (const key in result.attachments) {
          if (result.attachments.hasOwnProperty(key)) {
            const element = result.attachments[key];
            formData.append('attachments_files[]', element);
          }
        }
        this._api.updatePublication(this.id, formData, response => {
          if (response) {
            this._api.getPublication(this.id).subscribe((publication) => {
              this.Publication = publication;
              this.pubName = this.Publication.name;
              this.pubDesc = this.Publication.description;
              this.attachments = this.Publication.attachments;
              if (this.attachments.length > 1) {
                this.hasMoreImages = true;
              } else {
                this.hasMoreImages = false;
              }
              this.Links = this.Publication.links;
              this.render.removeClass(this.blocker.nativeElement, 'active');
              this.progress.done();
              this.snack.open('Updated Succesfully', 'OK', {
                duration: 2000
              });
            }, (err) => {
              this.render.removeClass(this.blocker.nativeElement, 'active');
              this.snack.open('Can not list, reload the page', 'OK', {
                duration: 2000
              });
            });
          } else {
            this.render.removeClass(this.blocker.nativeElement, 'active');
            this.progress.done();
            this.snack.open(result.statusText, 'OK', {
              duration: 2000
            });
          }
        });
      } else {
        this.render.removeClass(this.blocker.nativeElement, 'active');
        this.progress.done();
      }
    });
  }

  deleteAttachment(item) {
    const dialogRef = this.dialog.open(DialogDeletePubAttachComponent, {
      width: '300px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.render.addClass(this.blocker.nativeElement, 'active');
        this._api.deleteAttachment(item.id).subscribe((data) => {
          this._api.getPublication(this.id).subscribe((pub) => {
            this.Publication = pub;
            this.pubName = this.Publication.name;
            this.pubDesc = this.Publication.description;
            this.attachments = this.Publication.attachments;
            if (this.attachments.length > 1) {
              this.hasMoreImages = true;
            } else {
              this.hasMoreImages = false;
            }
            this.render.removeClass(this.blocker.nativeElement, 'active');
          }, (err2) => {
            this.render.removeClass(this.blocker.nativeElement, 'active');
            this.snack.open('Error can not load publication', 'OK', {
              duration: 4000
            });
          });
        }, (err) => {
          this.render.removeClass(this.blocker.nativeElement, 'active');
          this.snack.open('Error can not delete ', 'OK', {
            duration: 4000
          });
        });
      }
    });


  }

}
@Component({
  selector: 'app-dialog-delete',
  templateUrl: 'dialog-delete.html',
  styleUrls: ['./blog-detail.component.sass']
})
export class DialogDeletePubAttachComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogDeletePubAttachComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  // Pass the data from the modal to the component
  onClick() {
    this.dialogRef.close(true);
  }
}


@Component({
  selector: 'app-dialog-update',
  templateUrl: 'dialog-update.html',
  styleUrls: ['./blog-detail.component.sass']
})
export class DialogUpdateBlogDComponent {
  pubName: string;
  pubDes: string;
  Links: any[];
  thumbs: any;
  files: any;
  pubId: any;
  BASE_URL_IMAGE: any;
  attachments: any;
  linksCreated: number;
  faTimes = faTimes;
  doc: any;
  auxLinks: any;
  selectedFiles: any;
  constructor(
    public dialogRef: MatDialogRef<DialogUpdateBlogDComponent>,
    private _api: ApiService,
    private snack: MatSnackBar,
    private progress: NgProgress,
    @Inject(MAT_DIALOG_DATA) public data: DialogData2,
    @Inject(DOCUMENT) document
  ) {
    this.doc = document;
    this.pubId = data.id;
    this.pubName = data.pubName;
    this.pubDes = data.pubDesc;
    this.Links = data.Links[0].split(',');
    this.linksCreated = this.Links.length;
    this.auxLinks = [];
    this.attachments = data.attachments;
    this.BASE_URL_IMAGE = BASE_URL_IMAGE;
    this.files = 'Upload files';
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  createLink() {
    this.linksCreated++;
    this.Links.push('');
  }
  deleteAttachment(id) {
    this.progress.start();
    this._api.deleteAttachment(id).subscribe((res) => {
      this._api.getPublication(this.pubId).subscribe((publication) => {
        this.attachments = publication['attachments'];
        this.progress.done();
        this.snack.open('Image deleted', 'DONE', {
          duration: 2000
        });
      });
    }, (err) => {
      this.progress.done();
      this.snack.open('Can not delete', 'DONE', {
        duration: 2000
      });
    });
  }
  onFileChanged(event) {
    this.selectedFiles = event.target.files;
    this.files = this.selectedFiles.length + ' Files Selected';
  }
  onClick() {
    this.Links.forEach((element, index) => {
      const id = this.doc.getElementById(index).value;
      this.auxLinks.push(id);
    });
    this.auxLinks = this.auxLinks.filter(function (item) {
      return !(item.length === 0);
    });
    this.Links = this.auxLinks;
    this.dialogRef.close({
      name: this.pubName,
      description: this.pubDes,
      links: this.Links,
      attachments: this.selectedFiles
    });
  }
}

