import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { BiographyComponent } from './biography/biography.component';
import { ContactComponent } from './contact/contact.component';
import { GalleryComponent } from './gallery/gallery.component';
import { GallerySliderComponent } from './gallery-slider/gallery-slider.component';
import { BioFirstComponent } from './bio-first/bio-first.component';
import { BioSecondComponent } from './bio-second/bio-second.component';
import { BioThirdComponent } from './bio-third/bio-third.component';
import { BioFourthComponent } from './bio-fourth/bio-fourth.component';
import { LoginComponent } from './admin/login/login.component';
import { PanelComponent } from './admin/panel/panel.component';
import { MenuComponent } from './admin/panel/menu/menu.component';
import { CategoriesComponent } from './admin/panel/categories/categories.component';
import { InboxComponent } from './admin/panel/inbox/inbox.component';
import { CategoriesDetailComponent } from './admin/panel/categories-detail/categories-detail.component';
import { PostDetailComponent } from './admin/panel/post-detail/post-detail.component';
import { PublicationsComponent } from './publications/publications.component';
import { SubscribersComponent } from './admin/panel/subscribers/subscribers.component';
import { RentComponent } from './rent/rent.component';
import { BlogComponent } from './admin/panel/blog/blog.component';
import { GallerySliderToggleComponent } from './gallery-slider-toggle/gallery-slider-toggle.component';
import { BlogDetailComponent } from './admin/panel/blog-detail/blog-detail.component';
import { PublicationDetailComponent } from './publication-detail/publication-detail.component';
import { SalesComponent } from './admin/panel/sales/sales.component';
import { SalesDetailComponent } from './admin/panel/sales-detail/sales-detail.component';
import { AuthGuard } from './guards/auth.guard';
import { PublicationMediaComponent } from './publication-media/publication-media.component';
import { BiographyControlComponent } from './admin/panel/biography-control/biography-control.component';
const routes: Routes = [
    { path: '', component: HomePageComponent },
    {
        path: 'biography',
        component: BiographyComponent,
        children: [
            {
                path: '', component: BioFirstComponent
            },
            {
                path: 'page2', component: BioSecondComponent
            },
            {
                path: 'page3', component: BioThirdComponent
            },
            {
                path: 'page4', component: BioFourthComponent
            }
        ]
    },
    { path: 'contact', component: ContactComponent },
    { path: 'gallery', component: GalleryComponent },
    { path: 'gallery/slides/:id', component: GallerySliderComponent },
    { path: 'gallery/slides/cardmode/:id', component: GallerySliderToggleComponent },
    { path: 'admin', component: LoginComponent },
    {
        path: 'admin/panel', component: PanelComponent, canActivate: [AuthGuard],
        children: [
            { path: '', component: MenuComponent, canActivate: [AuthGuard] },
            { path: 'categories', component: CategoriesComponent, canActivate: [AuthGuard] },
            { path: 'blog', component: BlogComponent, canActivate: [AuthGuard] },
            { path: 'blog-details/:id', component: BlogDetailComponent, canActivate: [AuthGuard] },
            { path: 'inbox', component: InboxComponent, canActivate: [AuthGuard] },
            { path: 'category-detail/:id', component: CategoriesDetailComponent, canActivate: [AuthGuard] },
            { path: 'post-detail/:idCategory/:idPost', component: PostDetailComponent, canActivate: [AuthGuard] },
            { path: 'subscribers', component: SubscribersComponent, canActivate: [AuthGuard] },
            { path: 'sales', component: SalesComponent, canActivate: [AuthGuard] },
            { path: 'sales-detail/:id', component: SalesDetailComponent, canActivate: [AuthGuard] },
            { path: 'biography-control', component: BiographyControlComponent, canActivate: [AuthGuard] }
        ]
    },
    { path: 'publications', component: PublicationsComponent },
    { path: 'publications-detail/:id', component: PublicationDetailComponent },
    { path: 'publication-media/:id', component: PublicationMediaComponent },
    { path: 'post/buy/:id/:idcat', component: RentComponent }

];

@NgModule({
    imports: [RouterModule.forRoot(routes)], exports: [RouterModule]
})
export class AppRoutingModule { }
