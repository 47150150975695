import { Component, OnInit, Inject, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';
import { NgProgress } from 'ngx-progressbar';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
const BASE_URL_IMAGE = environment.apiImageUrl;



export class DialogData {
  id: any;
  name: any;
  description: any;
  links: any[];
  attachments: any;
  thumb: any;
  status: boolean;
}
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.sass']
})
export class BlogComponent implements OnInit {
  BASE_URL_IMAGE = BASE_URL_IMAGE;
  hasPublications: boolean;

  displayedColumns: string[];
  dataSource: any;
  Publications: any;

  @ViewChild('block') blocker: ElementRef;
  constructor(
    private _api: ApiService,
    private dialog: MatDialog,
    private progress: NgProgress,
    private router: Router,
    private renderer: Renderer2,
    private spinner: NgxSpinnerService,
    private snack: MatSnackBar,
    @Inject(DOCUMENT) document
  ) { }

  ngOnInit() {
    this.hasPublications = false;
    this.renderPublications();
  }

  openPublication(id) {
    this.router.navigateByUrl('/admin/panel/blog-details/' + id);
  }

  renderPublications() {
    this.spinner.show();
    this._api.getPublications().subscribe((data) => {
      this.Publications = data;
      this.dataSource = data;
      this.spinner.hide();
      if (this.Publications.length > 0) {
        this.hasPublications = true;
      } else {
        this.hasPublications = false;
      }
      this.displayedColumns = ['ID', 'NAME', 'ACTION'];
    }, (err) => { });
  }

  openDialogCreate() {
    const dialogRef = this.dialog.open(DialogCreatePublicationComponent, {
      width: '700px',
      minHeight: '500px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        if (result.files !== undefined) {
          this.renderer.addClass(this.blocker.nativeElement, 'active');
          this.progress.start();
          const formData = new FormData();
          const files = result.files;
          formData.append('name', result.name);
          formData.append('description', result.description);
          formData.append('links[]', result.links);
          for (const key in files) {
            if (files.hasOwnProperty(key)) {
              const element = files[key];
              formData.append('attachments_files[]', element);
            }
          }
          this.progress.set(0.1);
          this._api.createPublication(formData, response => {
            if (response) {
              this.progress.set(0.5);
              this._api.getPublications().subscribe((publications) => {
                this.Publications = publications;
                this.dataSource = publications;
                this.displayedColumns = ['ID', 'NAME', 'ACTION'];
                this.renderer.removeClass(this.blocker.nativeElement, 'active');
                if (this.Publications.length > 0) {
                  this.hasPublications = true;
                } else {
                  this.hasPublications = false;
                }
                this.snack.open('Created publication', 'OK', {
                  duration: 2000
                });
                this.progress.done();
              }, (err) => {
                this.renderer.removeClass(this.blocker.nativeElement, 'active');
                this.progress.done();
                console.log(err);
                this.snack.open(err.statusText, 'OK', {
                  duration: 2000
                });
              });
            } else {
              this.renderer.removeClass(this.blocker.nativeElement, 'active');
              this.snack.open('An error ocurred, try again', 'OK', {
                duration: 2000
              });
            }
          });
        } else {
          this.snack.open('Upload at least one file ', 'OK', { duration: 3000 });
        }

      }
    });
  }
  openDialogUpdate(id) {
    this._api.getPublication(id).subscribe((publication) => {
      const dialogRef = this.dialog.open(DialogUpdatePublicationComponent, {
        width: '700px',
        minHeight: '500px',
        data: {
          id: publication['id'],
          name: publication['name'],
          description: publication['description'],
          links: publication['links'],
          attachments: publication['attachments'],
          thumb: publication['attachments'][0].file.thumb.url
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.progress.start();
          this.renderer.addClass(this.blocker.nativeElement, 'active');
          const formData = new FormData();
          formData.append('name', result.name);
          formData.append('description', result.description);
          formData.append('links[]', result.links);
          for (const key in result.attachments) {
            if (result.attachments.hasOwnProperty(key)) {
              const element = result.attachments[key];
              formData.append('attachments_files[]', element);
            }
          }
          this._api.updatePublication(id, formData, response => {
            if (response) {
              this._api.getPublications().subscribe((publications) => {
                this.Publications = publications;
                this.dataSource = publications;
                this.renderer.removeClass(this.blocker.nativeElement, 'active');
                if (this.Publications.length > 0) {
                  this.hasPublications = true;
                } else {
                  this.hasPublications = false;
                }
                this.progress.done();
                this.snack.open('Updated Succesfully', 'OK', {
                  duration: 2000
                });
                this.displayedColumns = ['ID', 'NAME', 'ACTION'];
              }, (err) => {
                this.renderer.removeClass(this.blocker.nativeElement, 'active');
                this.snack.open('Can not list, reload the page', 'OK', {
                  duration: 2000
                });
              });
            } else {
              this.renderer.removeClass(this.blocker.nativeElement, 'active');
              this.progress.done();
              this.snack.open(result.statusText, 'OK', {
                duration: 2000
              });
            }
          });
        } else {
          this.renderer.removeClass(this.blocker.nativeElement, 'active');
          this.progress.done();
        }
      });
    });

  }

  openDialogDelete(id, status) {
    const dialogRef = this.dialog.open(DialogDeletePublicationComponent, {
      width: '350px',
      data: {
        status: status
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.progress.start();
        this.renderer.addClass(this.blocker.nativeElement, 'active');
        let body;
        if (!status) {
          body = { 'status': true };
        } else {
          body = { 'status': false };
        }
        this._api.disablePublication(id, body).subscribe((data) => {
          this._api.getPublications().subscribe((data2) => {
            this.Publications = data2;
            this.dataSource = data2;
            this.renderer.removeClass(this.blocker.nativeElement, 'active');
            this.progress.done();
            if (this.Publications.length > 0) {
              this.hasPublications = true;
            } else {
              this.hasPublications = false;
            }
            if (status) {
              this.snack.open('Succesfully disabled', 'OK', {
                duration: 4000,
              });
            } else {
              this.snack.open('Succesfully enabled', 'OK', {
                duration: 4000,
              });
            }
            this.displayedColumns = ['ID', 'NAME', 'ACTION'];
          }, (err) => {
            this.renderer.removeClass(this.blocker.nativeElement, 'active');
            this.progress.done();
            this.snack.open(err.statusText, 'OK', {
              duration: 2000
            });
          });
        }, (err) => {
          this.renderer.removeClass(this.blocker.nativeElement, 'active');
          this.progress.done();
          this.snack.open(err.statusText, 'OK', {
            duration: 2000
          });
        });
      }
    });
  }
}



// Dialog Create

@Component({
  selector: 'app-dialog-creqte',
  templateUrl: 'dialog-create.html',
  styleUrls: ['./blog.component.sass']
})
export class DialogCreatePublicationComponent {
  files: string;
  pubName: string;
  pubDesc: string;
  links: any;
  selectedLinks: any;
  selectedFiles: any;
  linksCreated: number;

  doc: any;
  constructor(
    public dialogRef: MatDialogRef<DialogCreatePublicationComponent>,
    @Inject(DOCUMENT) document
    // @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.files = 'Select a File';
    this.links = [];
    this.linksCreated = 0;
    this.selectedLinks = [];
    this.doc = document;
  }

  onFileChanged(event) {
    this.selectedFiles = event.target.files;
    this.files = this.selectedFiles.length + ' Files Selected';
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
  createLink() {
    this.links.push({ number: this.linksCreated });
    this.linksCreated++;
  }
  onClick() {
    this.links.forEach(element => {
      const input = this.doc.getElementById(element.number).value;
      this.selectedLinks.push(input);
    });
    console.log(this.selectedLinks);
    this.dialogRef.close({
      links: this.selectedLinks,
      name: this.pubName,
      description: this.pubDesc,
      files: this.selectedFiles
    });
  }

}


// Dialog delete
@Component({
  selector: 'app-dialog-confirm',
  templateUrl: 'dialog-confirm.html',
  styleUrls: ['./blog.component.sass']
})
export class DialogDeletePublicationComponent {
  files: string;
  categoryName: string;
  categoryDesc: string;
  selectedFiles: any[];

  messageEnabled: boolean;
  constructor(
    public dialogRef: MatDialogRef<DialogDeletePublicationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.messageEnabled = data.status;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClick() {
    this.dialogRef.close(true);
  }
}



// Dialog Update

@Component({
  selector: 'app-dialog-update',
  templateUrl: 'dialog-update.html',
  styleUrls: ['./blog.component.sass']
})
export class DialogUpdatePublicationComponent {
  pubName: string;
  pubDes: string;
  Links: any[];
  thumbs: any;
  files: any;
  pubId: any;
  BASE_URL_IMAGE: any;
  attachments: any;
  linksCreated: number;
  faTimes = faTimes;
  doc: any;
  hasMoreImages: boolean;
  auxLinks: any;
  selectedFiles: any;
  constructor(
    public dialogRef: MatDialogRef<DialogUpdatePublicationComponent>,
    private _api: ApiService,
    private snack: MatSnackBar,
    private progress: NgProgress,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    @Inject(DOCUMENT) document
  ) {
    this.doc = document;
    this.pubId = data.id;
    this.pubName = data.name;
    this.pubDes = data.description;
    this.Links = data.links[0].split(',');
    this.linksCreated = this.Links.length;
    this.auxLinks = [];
    this.attachments = data.attachments;
    if (this.attachments.length > 1) {
      this.hasMoreImages = true;
    } else {
      this.hasMoreImages = false;
    }
    this.BASE_URL_IMAGE = BASE_URL_IMAGE;
    this.thumbs = data.thumb;
    this.files = 'Upload files';
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  createLink() {
    this.linksCreated++;
    this.Links.push('');
  }
  deleteAttachment(id) {
    this.progress.start();
    this._api.deleteAttachment(id).subscribe((res) => {
      this._api.getPublication(this.pubId).subscribe((publication) => {
        this.attachments = publication['attachments'];
        if (this.attachments.length > 1) {
          this.hasMoreImages = true;
        } else {
          this.hasMoreImages = false;
        }
        this.progress.done();
        this.snack.open('Image deleted', 'DONE', {
          duration: 2000
        });
      });
    }, (err) => {
      this.progress.done();
      this.snack.open('Can not delete', 'DONE', {
        duration: 2000
      });
    });
  }
  onFileChanged(event) {
    this.selectedFiles = event.target.files;
    this.files = this.selectedFiles.length + ' Files Selected';
  }
  onClick() {
    this.Links.forEach((element, index) => {
      const id = this.doc.getElementById(index).value;
      this.auxLinks.push(id);
    });
    this.auxLinks = this.auxLinks.filter(function (item) {
      return !(item.length === 0);
    });
    this.Links = this.auxLinks;
    this.dialogRef.close({
      name: this.pubName,
      description: this.pubDes,
      links: this.Links,
      attachments: this.selectedFiles
    });
  }
}
