import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { DataService } from './data.service';
import { environment } from 'src/environments/environment';




const API_URL = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})

export class AuthService {


  httpOptions: any;
  httpOptions2: any;

  constructor(private http: HttpClient, private router: Router, private _data: DataService) {


    this.httpOptions2 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
  }
  authenticate(payload) {
    console.log(payload);
    return this.http
      .post(API_URL + '/admin/login', payload)
      .pipe(response => {
        return (response);
      });
  }


  public isAuthenticated(): boolean {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token && token !== undefined) {
      return true;
    } else {
      return false;
    }
  }

  adminLogOut() {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
      .delete(API_URL + '/admin/logout/', this.httpOptions)
      .pipe(response => {
        return (response);
      });
  }

}
