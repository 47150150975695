import { Component, OnInit } from '@angular/core';
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/free-solid-svg-icons';
import { EmmiterColorService } from '../emmiter-color.service';
@Component({
  selector: 'app-bio-third',
  templateUrl: './bio-third.component.html',
  styleUrls: ['./bio-third.component.sass']
})
export class BioThirdComponent implements OnInit {
  faChevronCircleDown = faChevronCircleDown;
  faChevronCircleUp = faChevronCircleUp;
  constructor(private _data: EmmiterColorService) { }

  ngOnInit() {
    this._data.changeChildThree(true);
  }

}
