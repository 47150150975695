import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor() { }

  private dataCategory = new BehaviorSubject('Default');

  currentSchedule = this.dataCategory.asObservable();

  changeCategory(category: any) {
    this.dataCategory.next(category);
  }


}
