import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmmiterColorService {

  constructor() { }


  private childOneActive = new BehaviorSubject(false);
  private childTwoActive = new BehaviorSubject(false);
  private childThreeActive = new BehaviorSubject(false);
  private childFourthActive = new BehaviorSubject(false);

  private galleryTopic = new BehaviorSubject('Default');

  currentChildOne = this.childOneActive.asObservable();
  currentChildTwo = this.childTwoActive.asObservable();
  currentChildThree = this.childThreeActive.asObservable();
  currentChildFourth = this.childFourthActive.asObservable();
  currentTopic = this.galleryTopic.asObservable();

  changeChildOne(active: any) {
    this.childOneActive.next(active);
    this.childTwoActive.next(false);
    this.childThreeActive.next(false);
    this.childFourthActive.next(false);
  }
  changeChildTwo(active: any ) {
    this.childOneActive.next(false);
    this.childTwoActive.next(active);
    this.childThreeActive.next(false);
    this.childFourthActive.next(false);
  }
  changeChildThree(active: any) {
    this.childOneActive.next(false);
    this.childTwoActive.next(false);
    this.childThreeActive.next(active);
    this.childFourthActive.next(false);
  }
  changeChildFourth(active: any) {
    this.childOneActive.next(false);
    this.childTwoActive.next(false);
    this.childThreeActive.next(false);
    this.childFourthActive.next(active);
  }
  changeTopic(topic: any) {
    this.galleryTopic.next(topic);
  }
}
