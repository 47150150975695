import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { NgProgress } from 'ngx-progressbar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {


  email: any;
  password: any;

  constructor(
    private _auth: AuthService,
    private router: Router,
    private progress: NgProgress,
    private snack: MatSnackBar
  ) { }

  ngOnInit() {
  }


  logIn() {
    this.progress.start();
    const body = {
      'email': this.email,
      'password': this.password
    };
    this._auth.authenticate(body).subscribe((response) => {
      localStorage.setItem('AuthToken', JSON.stringify(response));
      this.progress.done();
      this.router.navigateByUrl('admin/panel');
    }, (error) => {
      this.progress.done();
      this.snack.open('Invalid credentials, try again', 'OK', {duration: 4000});
    });
  }

}
