import { Component, OnInit, Renderer2, ViewChild, ElementRef, HostListener, Inject } from '@angular/core';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { EmmiterColorService } from '../emmiter-color.service';
import {Router} from '@angular/router';
import { ApiService } from '../services/api.service';
import { DOCUMENT } from '@angular/platform-browser';
import { WINDOW } from '../windows.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material';

const baseUrl = environment.apiUrl;

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.sass']
})
export class GalleryComponent implements OnInit {
  faBars = faBars;
  faTimes = faTimes;
  BASE_URL: any;
  Posts: any;
  opaque: any;
  Categories: any;
  @ViewChild('sidenav') sidenav: ElementRef;
  @ViewChild('list') list: ElementRef;
  constructor(private _data: EmmiterColorService,
              private router: Router,
              private renderer: Renderer2,
              private _api: ApiService,
              private spinner: NgxSpinnerService,
              public translate: TranslateService,
              private snack: MatSnackBar,
              @Inject(DOCUMENT) private document: Document,
              @Inject(WINDOW) private window: Window) { }

  ngOnInit() {
    this.BASE_URL = baseUrl;
    window.scroll(0, 0);
    this.opaque = false;
    this.document.body.classList.remove('noscroll');
    this.spinner.show();
    this._api.listCategoriesAsGuest().subscribe((data) => {
      this.Categories = data;
      setTimeout(() => {
        this.spinner.hide();
      }, 2000);
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const number = this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
    if (number > 100) {
      this.opaque = true;
    } else if (this.opaque && number < 10) {
      this.opaque = false;
    }
  }
  moveToSlides(id) {
    this._api.getAllPostsAsGuest(id).subscribe((data) => {
      this.Posts = data;
      this.router.navigateByUrl('/gallery/slides/cardmode/' + id);
      // if ( this.Posts.length > 3) {
      //   this.router.navigateByUrl('/gallery/slides/cardmode/' + id);
      // } else {
      //   this.router.navigateByUrl('/gallery/slides/cardmode/' + id);
      // }
    }, (err) => {
      this.snack.open(err.statusText, 'OK', {duration: 4000});
    });
  }

  translatePage(event) {
    // console.log(event.target.innerHTML);
    if (event.target.innerHTML === 'SPANISH') {
      this.translate.use('es');
    } else {
      this.translate.use('en');
    }
  }

  openSideNav() {
    this.renderer.addClass(this.sidenav.nativeElement, 'open');
    this.renderer.removeClass(this.list.nativeElement, 'paused');
    this.document.body.classList.add('noscroll');
    
  }
  closeSideNav() {
    console.log(this.sidenav.nativeElement);
    this.renderer.removeClass(this.sidenav.nativeElement, 'open');
    this.document.body.classList.remove('noscroll');
    this.list.nativeElement.forEach(element => {
      element.classList.remove('appear');
    });
    this.renderer.addClass(this.list.nativeElement, 'paused');
    this.list.nativeElement.forEach(element => {
      element.classList.add('appear');
    });

  }

}
