import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.sass']
})
export class PanelComponent implements OnInit {

  constructor(
    private router: Router,
    private _auth: AuthService,
    private snack: MatSnackBar
  ) { }

  ngOnInit() {
  }

  logOut() {
    this._auth.adminLogOut().subscribe((response) => {
      localStorage.removeItem('AuthToken');
      this.router.navigateByUrl('admin');
    }, (error) => {
      this.snack.open('Session error, reload', 'OK', {duration: 4000});
    });
  }

}
