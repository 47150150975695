import { Component, OnInit, ViewChild, ElementRef, Renderer2, Inject } from '@angular/core';
import { faBars, faPaperPlane, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ApiService } from '../services/api.service';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { NgProgress } from 'ngx-progressbar';
import { DOCUMENT } from '@angular/platform-browser';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.sass']
})
export class ContactComponent implements OnInit {
  faBars = faBars;
  faPaperPlane = faPaperPlane;
  faTimes = faTimes;



  fullname: string;
  email: string;
  comments: any;
  @ViewChild('block') blocker: ElementRef;

  @ViewChild('sidenav') sidenav: ElementRef;
  @ViewChild('list') list: ElementRef;
  constructor(
    private renderer: Renderer2,
    private _api: ApiService,
    public translate: TranslateService,
    private progress: NgProgress,
    @Inject(DOCUMENT) private document: Document,
    private snack: MatSnackBar) {
  }

  ngOnInit() {
    this.fullname = '';
    this.email = '';
    this.comments = '';

    this.document.body.classList.remove('noscroll');

  }



  translatePage(event) {
    // console.log(event.target.innerHTML);
    if (event.target.innerHTML === 'SPANISH') {
      this.translate.use('es');
    } else {

      this.translate.use('en');
    }
  }

  sendBtn() {
    this.progress.start();

    this.renderer.addClass(this.blocker.nativeElement, 'active');
    const valitaded = this.validate();
    if (valitaded) {
      const body = {
        'full_name': this.fullname,
        'email': this.email,
        'body': this.comments
      };
      this._api.createMessage(body).subscribe((data) => {
        this.fullname = '';
        this.email = '';
        this.comments = '';
        this.renderer.removeClass(this.blocker.nativeElement, 'active');
        this.progress.done();
        this.snack.open('Message sended to Cecilia', 'OK', {
          duration: 4000,
        });
      }, (err) => {
        this.snack.open('Error sending the message, try again', 'OK', {
          duration: 4000,
        });
      });

    } else {
      this.renderer.removeClass(this.blocker.nativeElement, 'active');
      this.progress.done();
      this.snack.open('Empty fields', 'OK', {
        duration: 4000,
      });
    }
  }


  validate() {

    if (this.fullname && this.email && this.comments) {
      if (this.fullname !== undefined && this.email !== undefined && this.comments !== undefined) {
        if (this.fullname.trim().length !== 0 &&
          this.email.trim().length > 0
          && this.comments.trim().length > 0) {
          return true;
        } else {
          return false;
        }
      }
    }


  }
  openSideNav() {
    this.renderer.addClass(this.sidenav.nativeElement, 'open');
    this.renderer.removeClass(this.list.nativeElement, 'paused');
    this.document.body.classList.add('noscroll');
  }
  closeSideNav() {
    console.log(this.sidenav.nativeElement);
    this.renderer.removeClass(this.sidenav.nativeElement, 'open');
    this.document.body.classList.remove('noscroll');
    this.list.nativeElement.forEach(element => {
      element.classList.remove('appear');
    });
    this.renderer.addClass(this.list.nativeElement, 'paused');
    this.list.nativeElement.forEach(element => {
      element.classList.add('appear');
    });

  }

}
