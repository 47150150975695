import { Component, OnInit, Inject, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgProgress } from 'ngx-progressbar';
import { ApiService } from 'src/app/services/api.service';
import { MatPaginator, MatTableDataSource, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
export interface DialogData2 {
  message: any;
  name: any;
}
@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.sass']
})
export class InboxComponent implements OnInit {

  Messages: any;
  totalMessages: any;
  MessagesNews: any;
  dataSource;
  displayedColumns: string[];
  @ViewChild('block') blocker: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  hasMessages: boolean;
  constructor(
    private spinner: NgxSpinnerService,
    private progress: NgProgress,
    private _api: ApiService,
    private snack: MatSnackBar,
    private dialog: MatDialog,
    private render: Renderer2
  ) { }

  ngOnInit() {
    this.renderMessages();
    this.displayedColumns = ['Email', 'Action'];
  }

  openDialog(item) {
    const dialogRef = this.dialog.open(DialogShowComponent, {
      width: '500px',
      minHeight: '200px',
      data: {
        message: item.body,
        name: item.full_name
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  openDialogDelete(item) {
    const dialogRef = this.dialog.open(DialogDeleteMessageComponent, {
      minWidth: '350px',
      data: {
        message: item.body,
        name: item.full_name
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.render.addClass(this.blocker.nativeElement, 'active');
        this.progress.start();
        this._api.deleteMessage(item.id).subscribe((data) => {
          this._api.getAllMessages().subscribe((messages) => {
            this.Messages = messages;
            this.dataSource = new MatTableDataSource(this.Messages);
            this.dataSource.paginator = this.paginator;
            this.totalMessages = this.Messages.length;
            if (this.totalMessages !== 0) {
              this.hasMessages = true;
            } else {
              this.hasMessages = false;
            }
            this.render.removeClass(this.blocker.nativeElement, 'active');
            this.progress.done();
            this.snack.open('Deleted succesfully', 'OK', {
              duration: 3000
            });
          });

        }, (err) => {
          this.render.removeClass(this.blocker.nativeElement, 'active');
          this.snack.open('Can not delete, try again', 'OK', {
            duration: 3000
          });
        });
      }
    });
  }

  openModal() {
    const dialogRef = this.dialog.open(DialogCreateMessage2Component, {
      minWidth: '350px'
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  renderMessages() {
    this.spinner.show();
    this._api.getAllMessages().subscribe((data) => {
      this.Messages = data;
      this.dataSource = new MatTableDataSource(this.Messages);
      this.dataSource.paginator = this.paginator;
      this.totalMessages = this.Messages.length;
      if (this.totalMessages !== 0) {
        this.hasMessages = true;
      } else {
        this.hasMessages = false;
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      this.snack.open('Conection error, try again', 'OK', {
        duration: 4000
      });
    });
  }



}

// modal for show message
@Component({
  selector: 'app-dialog-show',
  templateUrl: 'dialog-show.html',
  styleUrls: ['./inbox.component.sass']
})
export class DialogShowComponent {
  message: any;
  name: any;
  constructor(
    public dialogRef: MatDialogRef<DialogShowComponent>,
    private progress: NgProgress,
    @Inject(MAT_DIALOG_DATA) public data: DialogData2
  ) {
    this.message = data.message;
    this.name = data.name;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClick() {
    this.dialogRef.close({});
  }

}

// Dialog create message

@Component({
  selector: 'app-dialog-message',
  templateUrl: 'dialog-create.html',
  styleUrls: ['./inbox.component.sass']
})
export class DialogCreateMessage2Component {
  messageToSubs: any;
  constructor(
    public dialogRef: MatDialogRef<DialogCreateMessage2Component>,
    private progress: NgProgress,
    @Inject(MAT_DIALOG_DATA) public data: DialogData2
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClick() {
    this.dialogRef.close({});
  }

}



// Modal for delete message

@Component({
  selector: 'app-dialog-delete',
  templateUrl: 'dialog-delete.html',
  styleUrls: ['./inbox.component.sass']
})
export class DialogDeleteMessageComponent {
  message: any;
  name: any;
  constructor(
    public dialogRef: MatDialogRef<DialogDeleteMessageComponent>,
    private progress: NgProgress,
    @Inject(MAT_DIALOG_DATA) public data: DialogData2
  ) {
    this.message = data.message;
    this.name = data.name;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClick() {
    this.dialogRef.close(true);
  }
}
