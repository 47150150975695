import { Component, OnInit, Renderer2, ElementRef, Inject, HostListener, ViewChild } from '@angular/core';
import { ApiService } from '../services/api.service';
import { DOCUMENT } from '@angular/platform-browser';
import { WINDOW } from '../windows.service';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';


const BASE_IMAGE_URL = environment.apiImageUrl;
@Component({
  selector: 'app-publications',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.sass']
})
export class PublicationsComponent implements OnInit {
  faBars = faBars;
  faTimes = faTimes;
  opaque: boolean;

  BASE_IMAGE_URL = BASE_IMAGE_URL;


  // Api Conections

  Publications: any;

  @ViewChild('loader') loader: ElementRef;
  @ViewChild('list') list: ElementRef;
  @ViewChild('sidenav') sidenav: ElementRef;
  constructor(
    private renderer: Renderer2,
    public el: ElementRef,
    private _api: ApiService,
    private spinner: NgxSpinnerService,
    private router: Router,
    public translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window) {
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const number = this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
    if (number > 100) {
      this.opaque = true;
    } else if (this.opaque && number < 10) {
      this.opaque = false;
    }


  }
  ngOnInit() {
    window.scrollTo(0, 0);
    this.document.body.classList.remove('noscroll');
    this.spinner.show();
    this.document.body.classList.add('noscroll');
    this.opaque = false;
    this.renderPublications();
  }


  translatePage(event) {
    // console.log(event.target.innerHTML);
    if (event.target.innerHTML === 'SPANISH') {
      this.translate.use('es');
    } else {
      this.translate.use('en');
    }
  }

  renderPublications() {
    this._api.getAllPublicationsAsGuest().subscribe((data) => {
      this.Publications = data;
      this.spinner.hide();
      this.document.body.classList.remove('noscroll');
    });
  }
  showMore(id) {
    this.router.navigateByUrl('/publications-detail/' + id);
  }
  openSideNav() {
    this.renderer.addClass(this.sidenav.nativeElement, 'open');
    this.renderer.removeClass(this.list.nativeElement, 'paused');
    this.document.body.classList.add('noscroll');
  }
  closeSideNav() {
    this.renderer.removeClass(this.sidenav.nativeElement, 'open');
    this.document.body.classList.remove('noscroll');
    this.list.nativeElement.forEach(element => {
      element.classList.remove('appear');
    });
    this.renderer.addClass(this.list.nativeElement, 'paused');
    this.list.nativeElement.forEach(element => {
      element.classList.add('appear');
    });
  }
}
