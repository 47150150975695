import { Component, OnInit, Inject, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { DOCUMENT } from '@angular/platform-browser';
import Drift from 'drift-zoom';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';

const BASE_URL_IMAGE = environment.apiImageUrl;

@Component({
  selector: 'app-publication-media',
  templateUrl: './publication-media.component.html',
  styleUrls: ['./publication-media.component.sass']
})
export class PublicationMediaComponent implements OnInit {


  faBars = faBars;
  faTimes = faTimes;
  hasLinks: boolean;

  BASE_URL_IMAGE = BASE_URL_IMAGE;

  opaque: boolean;
  id: any;
  pubName: any;
  pubDesc: any;

  _album: Array<any> = [];
  _album2 = new Array();
  Links: any;
  attachments: any;

  Publication: any;
  @ViewChild('list') list: ElementRef;
  @ViewChild('sidenav') sidenav: ElementRef;

  constructor(
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private _api: ApiService,
    private renderer: Renderer2,
    private _location: Location,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    this.opaque = false;
    window.scrollTo(0, 0);
    this.spinner.show();
    this.document.body.classList.add('noscroll');
    this.route.params.subscribe((param) => {
      this.id = +param['id'];
      this._api.getPublicationAsGuest(this.id).subscribe((pub) => {
        this.Publication = pub[0];
        this.pubDesc = this.Publication.description;
        this.pubName = this.Publication.name;
        if (this.Publication.links && this.Publication.links[0].length !== 0) {
          this.Links = this.Publication.links[0].split(',');
          this.hasLinks = true;
        } else {
          this.Links = [];
          this.hasLinks = false;
        }
        this.attachments = this.Publication.attachments;

        this.attachments.forEach(element => {
          const album = {
            src: BASE_URL_IMAGE + element.file.hd.url,
            thumb: BASE_URL_IMAGE + element.file.thumb.url,
          };
          this._album2.push(BASE_URL_IMAGE + element.file.thumb.url);
          this._album.push(album);
        });
        this.spinner.hide();
        this.document.body.classList.remove('noscroll');
      });

    });
  }

  back() {
    this._location.back();
  }

  translatePage(event) {
    if (event.target.innerHTML === 'SPANISH') {
      this.translate.use('es');
    } else {
      this.translate.use('en');
    }
  }


  openSideNav() {
    this.renderer.addClass(this.sidenav.nativeElement, 'open');
    this.document.body.classList.add('noscroll');
    this.renderer.removeClass(this.list.nativeElement, 'paused');
  }
  closeSideNav() {
    this.renderer.removeClass(this.sidenav.nativeElement, 'open');
    this.document.body.classList.remove('noscroll');
    this.list.nativeElement.forEach(element => {
      element.classList.remove('appear');
    });
    this.renderer.addClass(this.list.nativeElement, 'paused');
    this.list.nativeElement.forEach(element => {
      element.classList.add('appear');
    });
  }

  // initDrift(event) {
  //   // tslint:disable-next-line:no-unused-expression
  //   new Drift(document.querySelector('img.file'), {
  //     paneContainer: document.getElementById('cont'),
  //     zoomFactor: 3,
  //     inlinePane: -500,
  //     inlineOffsetY: 0,
  //     inlineContainer: document.getElementById('cont'),
  //     touchDelay: 300,
  //     containInline: true,
  //   });
  // }
}

