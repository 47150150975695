import { Component, OnInit, Inject, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, MatDialog, MatPaginator } from '@angular/material';
import { NgProgress } from 'ngx-progressbar';
import { MatSnackBar } from '@angular/material';

export interface DialogData {
  emailuser: any;
}
@Component({
  selector: 'app-subscribers',
  templateUrl: './subscribers.component.html',
  styleUrls: ['./subscribers.component.sass']
})
export class SubscribersComponent implements OnInit {
  hasSubscribers: boolean;
  dataSource;
  displayedColumns: string[];
  @ViewChild('block') blocker: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  Subscribers: any;


  constructor(
    private _api: ApiService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private render: Renderer2,
    private progress: NgProgress,
    private snack: MatSnackBar
  ) { }

  ngOnInit() {
    this.renderSubscribers();
    this.displayedColumns = ['Email', 'Action'];
  }

  openDialog2() {
    const dialogRef = this.dialog.open(DialogCreateMessageComponent, {
      width: '700px',
      minHeight: '500px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.progress.start();
        this.render.addClass(this.blocker.nativeElement, 'active');
        if (result) {
          const body = {
            'body': result.body,
            'title': result.title,
          };
          this._api.sendToSubscribers(body).subscribe((done) => {
            this.progress.done();
            this.render.removeClass(this.blocker.nativeElement, 'active');
            this.snack.open('Succesfully sended', 'OK', { duration: 4000 });
          }, (error) => {
            this.progress.done();
            this.render.removeClass(this.blocker.nativeElement, 'active');
            this.snack.open('Error', 'OK', { duration: 4000 });
          });
        } else {
          this.render.removeClass(this.blocker.nativeElement, 'active');
          this.progress.done();
          this.snack.open('An error occurs', 'OK', { duration: 4000 });
        }
      }
    });
  }
  opdenModalDelete(id, name) {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      width: '350px',
      data: { emailuser: name }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.render.addClass(this.blocker.nativeElement, 'active');
        this.progress.start();
        this._api.deleteSubscriber(id).subscribe((data) => {
          this._api.getAllSubscribers().subscribe((data2) => {
            this.Subscribers = data2;
            this.displayedColumns = ['Email', 'Action'];
            this.dataSource = data2;
            if (data2[0].length !== 0) {
              this.hasSubscribers = true;
            } else {
              this.hasSubscribers = false;
            }
            this.snack.open('Unsubscribed user', 'DONE', {
              duration: 2000
            });
            this.render.removeClass(this.blocker.nativeElement, 'active');
            this.progress.done();
          }, (err) => {
          });
        }, (err) => { });
      }

    });
  }




  renderSubscribers() {
    this.spinner.show();
    this._api.getAllSubscribers().subscribe((data) => {
      this.Subscribers = data;
      this.dataSource = new MatTableDataSource(this.Subscribers);
      this.dataSource.paginator = this.paginator;
      if (this.Subscribers.length !== 0) {
        this.hasSubscribers = true;
      } else {
        this.hasSubscribers = false;
      }
      this.spinner.hide();
    }, (err) => {
    });
  }
}

// Modal delete
@Component({
  selector: 'app-dialog-confirmation',
  templateUrl: 'dialog-delete.html',
  styleUrls: ['./subscribers.component.sass']
})
export class DialogDeleteComponent {
  emailuser: any;
  close: boolean;
  constructor(
    public dialogRef: MatDialogRef<DialogDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.emailuser = data.emailuser;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
  onClick() {
    this.dialogRef.close(true);
  }
}

// Modal create
@Component({
  selector: 'app-dialog-create',
  templateUrl: 'dialog-create.html',
  styleUrls: ['./subscribers.component.sass']
})
export class DialogCreateMessageComponent {
  messageBody: any;
  messageTitle: any;
  constructor(
    public dialogRef: MatDialogRef<DialogCreateMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
  onClick() {
    this.dialogRef.close({
      title: this.messageTitle,
      body: this.messageBody
    });
  }
}






