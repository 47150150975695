import { Component, OnInit, Inject, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { NgProgress } from 'ngx-progressbar';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatSnackBar } from '@angular/material';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Lightbox } from 'ngx-lightbox';
const BASE_URL = environment.apiUrl;
const BASE_URL_IMAGE = environment.apiImageUrl;

export interface DialogData3 {
  pictureName: string;
  picturePrice: any;
  pictureBrief: any;
  pictureDesc: any;
  attachments: any;
  postid: any;
  catid: any;
  pictureFile: any[];
  thumb: any;
}
@Component({
  selector: 'app-post-detail',
  templateUrl: './post-detail.component.html',
  styleUrls: ['./post-detail.component.sass']
})
export class PostDetailComponent implements OnInit {

  faTimes = faTimes;
  idCategory: any;
  idPost: any;
  Post: any;

  postName: any;
  postBrief: any;
  postDesc: any;
  postImages: any[];
  postPrice: any;

  _album: Array<any> = [];

  @ViewChild('block') blocker: ElementRef;
  thumb: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _api: ApiService,
    private progress: NgProgress,
    private render: Renderer2,
    public spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private _lightbox: Lightbox,
    public snack: MatSnackBar
  ) { }

  ngOnInit() {
    this.renderPost();
  }

  getBack() {
    this.router.navigateByUrl('/admin/panel/category-detail/' + this.idCategory).then((data) => {
      localStorage.setItem('startShowingPost', 'true');
    });
  }

  renderPost() {
    this.spinner.show();
    this.route.params.subscribe((param) => {
      this.idCategory = +param['idCategory'];
      this.idPost = +param['idPost'];
      this._api.getPost(this.idCategory, this.idPost).subscribe((data) => {
        this.postName = data['name'];
        this.postBrief = data['description'];
        this.postDesc = data['body'];
        this.postPrice = data['price'];
        this.postImages = data['attachments'];
        if (this.postImages[0].file.thumb.url !== undefined && this.postImages[0].file.thumb.url) {
          this.postImages.forEach(element => {
            const album = {
              src: BASE_URL_IMAGE + element.file.hd.url,
              thumb: BASE_URL_IMAGE + element.file.thumb.url,
            };
            this._album.push(album);
          });
        } else {
          this.thumb = 'assets/images/question-mar.png';
          this._album.push({src:  'assets/images/question-mar.png' , thumb:  'assets/images/question-mar.png' });
        }
        setTimeout(() => {
          this.spinner.hide();
        }, 300);
      });

    });
  }
  open(index: number): void {
    this._lightbox.open(this._album, index, {
      showImageNumberLabel: true,
      alwaysShowNavOnTouchDevices: true,
      disableScrolling: true,
      centerVertically: true
    });
  }
  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  openModalDelete() {
    const dialogRef = this.dialog.open(DialogDeletePost2Component, {
      width: '350px',
      minHeight: '250px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deletePost();
      }
    });
  }

  deletePost() {
    this.render.addClass(this.blocker.nativeElement, 'active');
    this.progress.start();
    this._api.deletePost(this.idCategory, this.idPost).subscribe((data) => {
      this.progress.done();
      this.render.removeClass(this.blocker.nativeElement, 'active');
      this.snack.open('Picture succesfully removed', 'OK', {
        duration: 2000
      });
      setTimeout(() => {
        this.router.navigateByUrl('/admin/panel/category-detail/' + this.idCategory);
      }, 2000);
    }, (err) => {
      this.render.removeClass(this.blocker.nativeElement, 'active');
      this.progress.done();
      this.snack.open(err, 'OK', {
        duration: 4000
      });
    });
  }

  openModalUpdate() {
    this._api.getPost(this.idCategory, this.idPost).subscribe((data) => {
      let thumbnail;
      const Post = JSON.parse(JSON.stringify(data));
      if (Post['attachments'][0].file.thumb.url !== undefined && Post['attachments'][0].file.thumb.url) {
        thumbnail = BASE_URL + data['attachments'][0].file.thumb.url;
      } else {
        thumbnail = null;
      }
      const dialogRef = this.dialog.open(DialogUpdate3PostComponent, {
        width: '700px',
        minHeight: '500px',
        data: {
          pictureName: data['name'],
          picturePrice: data['price'],
          pictureBrief: data['description'],
          pictureDesc: data['body'],
          catid: data['category_id'],
          postid: this.idPost,
          thumb: thumbnail,
          attachments: data['attachments']
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.editPost(result);
        }
      });
    }, (err) => { });


  }


  editPost(result) {
    this.progress.start();
    this.render.addClass(this.blocker.nativeElement, 'active');
    this.progress.set(0.1);

    const formData = new FormData();
    formData.append('name', result.pictureName);
    formData.append('description', result.pictureBrief);
    formData.append('body', result.pictureDesc);
    formData.append('price', result.picturePrice);

    const arr = result.pictureImage;
    for (const key in arr) {
      if (arr.hasOwnProperty(key)) {
        const element = arr[key];
        formData.append('attachments_files[]', element);
      }
    }
    this._api.updatePost(this.idCategory, this.idPost, formData, response => {
      if (response) {
        this.progress.set(0.5);
        this._api.getPost(this.idCategory, this.idPost).subscribe((data) => {
          this.Post = data;
          this.postName = data['name'];
          this.postBrief = data['description'];
          this.postDesc = data['body'];
          this.postPrice = data['price'];
          this.postImages = data['attachments'];
          this.thumb = BASE_URL + this.postImages[0].file.thumb.url;
          this.snack.open('Successfully Updated', 'OK', { duration: 3000 });
          this.render.removeClass(this.blocker.nativeElement, 'active');
          this.progress.done();
        });
      }
    });
  }

}

@Component({
  selector: 'app-dialog-delete',
  templateUrl: 'dialog-confirm.html',
  styleUrls: ['./post-detail.component.sass']
})
export class DialogDeletePost2Component {
  delete: any;
  constructor(
    public dialogRef: MatDialogRef<DialogDeletePost2Component>
  ) {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  // Pass the data from the modal to the component
  onClick() {
    this.dialogRef.close(
      {
        delete: true
      });
  }
}


// Dialog update post.
@Component({
  selector: 'app-dialog-update',
  templateUrl: 'dialog-update.html',
  styleUrls: ['./post-detail.component.sass']
})

export class DialogUpdate3PostComponent {
  files: string;
  pictureName: string;
  pictureBrief: string;
  faTimes = faTimes;
  pictureDesc: string;
  picturePrice: string;
  selectedFiles: any[];
  hasMorePost: boolean;
  attachments: any;
  idpost: any;
  idcat: any;
  BASE_URL_IMAGE = BASE_URL_IMAGE;
  currentThumb = 'assets/images/question-mar.png';
  constructor(
    public dialogRef: MatDialogRef<DialogUpdate3PostComponent>,
    private progress: NgProgress,
    private _api: ApiService,
    private snack: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData3
  ) {
    this.pictureName = data.pictureName;
    this.pictureDesc = data.pictureDesc;
    this.pictureBrief = data.pictureBrief;
    this.picturePrice = data.picturePrice;
    this.attachments = data.attachments;
    if (this.attachments.length > 1) {
      this.hasMorePost = true;
    } else {
      this.hasMorePost = false;
    }
    if (data.thumb !== null) {
      this.currentThumb = data.thumb;
    }
    this.idpost = data.postid;
    this.idcat = data.catid;
    this.selectedFiles = [];
    this.files = 'Change image';
  }

  deleteAttachment(item) {
    console.log(item.id);
    this.progress.start();
    this._api.deleteAttachment(item.id).subscribe((res) => {
      this._api.getPost(this.idcat, this.idpost).subscribe((publication) => {
        this.attachments = publication['attachments'];
        if (this.attachments.length > 1) {
          this.hasMorePost = true;
        } else {
          this.hasMorePost = false;
        }
        this.progress.done();
        this.snack.open('Image deleted', 'DONE', {
          duration: 2000
        });
      });
    }, (err) => {
      this.progress.done();
      this.snack.open('Can not delete', 'DONE', {
        duration: 2000
      });
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClick() {
    this.dialogRef.close({
      pictureName: this.pictureName,
      pictureBrief: this.pictureBrief,
      pictureDesc: this.pictureDesc,
      picturePrice: this.picturePrice,
      pictureImage: this.selectedFiles,
      currentThumb: this.currentThumb
    });
  }
  onFileChanged(event) {
    this.selectedFiles = event.target.files;
    console.log(this.selectedFiles);
    this.files = this.selectedFiles.length + ' Files Selected';
  }

}


