import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DataService } from './data.service';
import { Router } from '@angular/router';




const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  httpOptions: any;
  httpOption2: any;
  httpOption3: any;
  constructor(private http: HttpClient, private router: Router, private _data: DataService) {
  }
  deletePublication(id) {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOption3 = {
        headers: new HttpHeaders({
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
      .delete(API_URL + '/admin/publications/' + id, this.httpOption3)
      .pipe(response => {
        return (response);
      });
  }

  getPublication(id) {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOption3 = {
        headers: new HttpHeaders({
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
      .get(API_URL + '/admin/publications/' + id, this.httpOption3)
      .pipe(response => {
        return (response);
      });
  }
  updatePublication(id, formData, callback) {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    const xhr = new XMLHttpRequest();
    xhr.addEventListener('readystatechange', function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (this.readyState === 4 || this.readyState === 200) {
          callback(this.responseText);
        } else {
          callback(null);
        }
      }
    });
    xhr.open('PUT', API_URL + '/admin/publications/' + id);
    xhr.setRequestHeader('cache-control', 'no-cache');
    if (token !== null && token !== undefined) {
      xhr.setRequestHeader('Authorization', 'Token token=' + token.secret);
    }
    xhr.send(formData);
  }
  createPublication(formData, callback) {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    const xhr = new XMLHttpRequest();
    xhr.addEventListener('readystatechange', function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (this.readyState === 4 || this.readyState === 200) {
          callback(this.responseText);
        } else {
          callback(null);
        }
      }
    });
    xhr.open('POST', API_URL + '/admin/publications/');
    xhr.setRequestHeader('cache-control', 'no-cache');
    if (token !== null && token !== undefined) {
      xhr.setRequestHeader('Authorization', 'Token token=' + token.secret);
    }
    xhr.send(formData);
  }
  getPublications() {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOption3 = {
        headers: new HttpHeaders({
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
      .get(API_URL + '/admin/publications', this.httpOption3)
      .pipe(response => {
        return (response);
      });
  }
  deleteSubscriber(id) {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOption3 = {
        headers: new HttpHeaders({
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
      .delete(API_URL + '/admin/subscribers/' + id, this.httpOption3)
      .pipe(response => {
        return (response);
      });
  }
  getAllSubscribers() {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOption3 = {
        headers: new HttpHeaders({
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
      .get(API_URL + '/admin/subscribers', this.httpOption3)
      .pipe(response => {
        return (response);
      });
  }



  deleteMessage(id) {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOption3 = {
        headers: new HttpHeaders({
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
      .delete(API_URL + '/admin/messages/' + id, this.httpOption3)
      .pipe(response => {
        return (response);
      });
  }
  getRenderBiographyAsAdmin() {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOption3 = {
        headers: new HttpHeaders({
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
    .get(API_URL + '/admin/biografies/' + 1 , this.httpOption3)
    .pipe(response => {
      return (response);
    });
  }
  getRenderBiographyAsGuest() {
    return this.http
    .get(API_URL + '/guest/biografies/' + 1)
    .pipe(response => {
      return (response);
    });
  }

  editBiographyAsAdmin(body) {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOption3 = {
        headers: new HttpHeaders({
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
    .put(API_URL + '/admin/biografies/' + 1 , body, this.httpOption3)
    .pipe(response => {
      return (response);
    });
  }
  createMessage(body) {
    return this.http
      .post(API_URL + '/guest/messages', body)
      .pipe(response => {
        return (response);
      });
  }
  getAllMessages() {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOption3 = {
        headers: new HttpHeaders({
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
      .get(API_URL + '/admin/messages', this.httpOption3)
      .pipe(response => {
        return (response);
      });
  }
  uploadImages(formData, callback) {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    const xhr = new XMLHttpRequest();
    xhr.addEventListener('progress', function (evt) {
      console.log(evt);
    });
    xhr.addEventListener('readystatechange', function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (this.readyState === 4 || this.readyState === 200) {
          callback(JSON.parse(this.responseText));
        } else {
          callback(null);
        }
      }
    });
    xhr.open('POST', API_URL + '/admin/categories/');
    xhr.setRequestHeader('cache-control', 'no-cache');
    if (token !== null && token !== undefined) {
      xhr.setRequestHeader('Authorization', 'Token token=' + token.secret);
    }
    xhr.send(formData);
  }

  listCategories() {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
      .get(API_URL + '/admin/categories/', this.httpOptions)
      .pipe(response => {
        return (response);
      });
  }
  getCategory(id) {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
      .get(API_URL + '/admin/categories/' + id, this.httpOptions)
      .pipe(response => {
        return (response);
      });
  }

  // Posts.
  getAllPosts(id) {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOption3 = {
        headers: new HttpHeaders({
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
      .get(API_URL + '/admin/categories/' + id + '/posts/', this.httpOption3)
      .pipe(response => {
        return (response);
      });
  }

  getPost(idCategory, idPost) {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOption3 = {
        headers: new HttpHeaders({
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
      .get(API_URL + '/admin/categories/' + idCategory + '/posts/' + idPost, this.httpOption3)
      .pipe(response => {
        return (response);
      });
  }


  createPost(id, formData, callback) {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    const xhr = new XMLHttpRequest();
    xhr.addEventListener('readystatechange', function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (this.readyState === 4 || this.readyState === 200) {
          callback(this.responseText);
        } else {
          callback(null);
        }
      }
    });
    xhr.open('POST', API_URL + '/admin/categories/' + id + '/posts/');
    xhr.setRequestHeader('cache-control', 'no-cache');
    if (token !== null && token !== undefined) {
      xhr.setRequestHeader('Authorization', 'Token token=' + token.secret);
    }
    xhr.send(formData);
  }

  deleteAttachment(id) {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOption3 = {
        headers: new HttpHeaders({
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
      .delete(API_URL + '/admin/attachments/' + id, this.httpOption3)
      .pipe(response => {
        return (response);
      });
  }
  updateCategory(id, formData, callback) {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    const xhr = new XMLHttpRequest();
    xhr.addEventListener('readystatechange', function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (this.readyState === 4 || this.readyState === 200) {
          callback(this.responseText);
        } else {
          callback(null);
        }
      }
    });
    xhr.open('PUT', API_URL + '/admin/categories/' + id);
    xhr.setRequestHeader('cache-control', 'no-cache');
    if (token !== null && token !== undefined) {
      xhr.setRequestHeader('Authorization', 'Token token=' + token.secret);
    }
    xhr.send(formData);
  }

  sendNotification(body) {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOption3 = {
        headers: new HttpHeaders({
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
      .post(API_URL + '/admin/promotionals/', body, this.httpOption3)
      .pipe(response => {
        return (response);
      });
  }

  updatePost(idCategory, idPost, formData, callback) {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    const xhr = new XMLHttpRequest();
    xhr.addEventListener('readystatechange', function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (this.readyState === 4 || this.readyState === 200) {
          callback(this.responseText);
        } else {
          callback(null);
        }
      }
    });
    xhr.open('PUT', API_URL + '/admin/categories/' + idCategory + '/posts/' + idPost);
    xhr.setRequestHeader('cache-control', 'no-cache');
    if (token !== null && token !== undefined) {
      xhr.setRequestHeader('Authorization', 'Token token=' + token.secret);
    }
    xhr.send(formData);
  }


  deleteCategory(id) {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOption3 = {
        headers: new HttpHeaders({
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
      .delete(API_URL + '/admin/categories/' + id, this.httpOption3)
      .pipe(response => {
        return (response);
      });
  }

  disableCategory(id, body) {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOption3 = {
        headers: new HttpHeaders({
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
      .put(API_URL + '/admin/categories/' + id, body, this.httpOption3)
      .pipe(response => {
        return (response);
      });
  }

  disablePublication(id, body) {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOption3 = {
        headers: new HttpHeaders({
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
      .put(API_URL + '/admin/publications/' + id, body, this.httpOption3)
      .pipe(response => {
        return (response);
      });
  }

  getPostWithoutCategory(idPost) {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOption3 = {
        headers: new HttpHeaders({
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
      .get(API_URL + '/admin/posts/' + idPost, this.httpOption3)
      .pipe(response => {
        return (response);
      });
  }

  deletePost(idCategory, idPost) {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOption3 = {
        headers: new HttpHeaders({
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
      .delete(API_URL + '/admin/categories/' + idCategory + '/posts/' + idPost, this.httpOption3)
      .pipe(response => {
        return (response);
      });
  }
  disablePost(idCategory, idPost, body) {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOption3 = {
        headers: new HttpHeaders({
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
      .put(API_URL + '/admin/categories/' + idCategory + '/posts/' + idPost, body, this.httpOption3)
      .pipe(response => {
        return (response);
      });
  }



  // Publications
  getAllPublications() {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOption3 = {
        headers: new HttpHeaders({
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
      .get(API_URL + '/admin/publications/', this.httpOption3)
      .pipe(response => {
        return (response);
      });
  }


  // Sales solicitudes



  sendToSubscribers(body) {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOption3 = {
        headers: new HttpHeaders({
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
      .post(API_URL + '/admin/promotionals/', body, this.httpOption3)
      .pipe(response => {
        return (response);
      });
  }
  showSaleSolicitude(idPost, idSolicitude) {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOption3 = {
        headers: new HttpHeaders({
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
      .get(API_URL + '/admin/posts/' + idPost + '/sales/' + idSolicitude, this.httpOption3)
      .pipe(response => {
        return (response);
      });
  }

  showAllSolicitudes(idPost) {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOption3 = {
        headers: new HttpHeaders({
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
      .get(API_URL + '/admin/posts/' + idPost + '/sales/', this.httpOption3)
      .pipe(response => {
        return (response);
      });
  }
  deleteSaleSolicitude(idPost, idSolicitude) {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOption3 = {
        headers: new HttpHeaders({
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
      .delete(API_URL + '/admin/posts/' + idPost + '/sales/' + idSolicitude, this.httpOption3)
      .pipe(response => {
        return (response);
      });
  }

  showAllPost() {
    const token = JSON.parse(localStorage.getItem('AuthToken'));
    if (token !== null && token !== undefined) {
      this.httpOption3 = {
        headers: new HttpHeaders({
          'Authorization': 'Token token=' + token.secret
        })
      };
    }
    return this.http
      .get(API_URL + '/admin/posts/index_all', this.httpOption3)
      .pipe(response => {
        return (response);
      });
  }

  // Gest functions


  getCategoryAsGuest(id) {
    return this.http
      .get(API_URL + '/guest/categories/' + id)
      .pipe(response => {
        return (response);
      });
  }
  listCategoriesAsGuest() {
    return this.http
      .get(API_URL + '/guest/categories/')
      .pipe(response => {
        return (response);
      });
  }

  createSubscriber(body) {
    return this.http
      .post(API_URL + '/guest/subscribers/', body)
      .pipe(response => {
        return (response);
      });
  }

  createSaleSolicitude(idPost, body) {
    return this.http
      .post(API_URL + '/guest/posts/' + idPost + '/sales', body)
      .pipe(response => {
        return (response);
      });
  }

  getAllPostsAsGuest(id) {
    return this.http
      .get(API_URL + '/guest/categories/' + id + '/posts/')
      .pipe(response => {
        return (response);
      });
  }

  getPublicationAsGuest(id) {
    return this.http
      .get(API_URL + '/guest/publications/' + id)
      .pipe(response => {
        return (response);
      });
  }

  getAllPublicationsAsGuest() {
    return this.http
      .get(API_URL + '/guest/publications/')
      .pipe(response => {
        return (response);
      });
  }

  getPostAsGuest(idCategory, idPost) {
    return this.http
      .get(API_URL + '/guest/categories/' + idCategory + '/posts/' + idPost)
      .pipe(response => {
        return (response);
      });
  }




}





