import { Component, OnInit } from '@angular/core';
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/free-solid-svg-icons';
import { EmmiterColorService } from '../emmiter-color.service';
@Component({
  selector: 'app-bio-fourth',
  templateUrl: './bio-fourth.component.html',
  styleUrls: ['./bio-fourth.component.sass']
})
export class BioFourthComponent implements OnInit {

  faChevronCircleDown = faChevronCircleDown;
  faChevronCircleUp = faChevronCircleUp;
  constructor(private _data: EmmiterColorService) { }

  ngOnInit() {
    this._data.changeChildFourth(true);
  }

}
