import { Component, OnInit } from '@angular/core';
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
import { EmmiterColorService } from '../emmiter-color.service';
@Component({
  selector: 'app-bio-first',
  templateUrl: './bio-first.component.html',
  styleUrls: ['./bio-first.component.sass']
})
export class BioFirstComponent implements OnInit {
  faChevronCircleDown = faChevronCircleDown;
  constructor(private _data: EmmiterColorService) { }

  ngOnInit() {
    this._data.changeChildOne(true);
  }


}
