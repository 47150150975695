import { Component, OnInit, ViewChild, HostListener, Renderer2, ElementRef, ViewEncapsulation, Inject } from '@angular/core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { DOCUMENT } from '@angular/platform-browser';
import { WINDOW } from '../windows.service';

import * as $ from 'jquery';
import { ApiService } from '../services/api.service';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.sass'],
})

export class HomePageComponent implements OnInit {

  state = 'hide';

  faBars = faBars;
  faChevronDown = faChevronDown;
  faTimes = faTimes;

  currentSlide: any;
  currentThumb: any;
  activeSlider: boolean;


  emailSub: any;

  index: number;

  opaque: boolean;


  slides = ['Oil Painting', 'Jewerly Art', 'Porcelain Painting & More'];
  thumbs = ['assets/images/divinemarco.png', 'assets/images/marcojewerly.png', 'assets/images/marcoporcelain.png'];


  @ViewChild('loader') loader: ElementRef;
  @ViewChild('list') list: ElementRef;
  @ViewChild('sidenav') sidenav: ElementRef;


  constructor(
    private renderer: Renderer2,
    public el: ElementRef,
    private _api: ApiService,
    private snack: MatSnackBar,
    public translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window) {

    // translate.addLangs(['en', 'es']);
    // translate.setDefaultLang('en');
    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|es/) ? browserLang : 'en');
  }

  translatePage(event) {
    // console.log(event.target.innerHTML);
    if (event.target.innerHTML === 'SPANISH') {
      this.translate.use('es');
    } else {
      this.translate.use('en');
    }
  }

  ngOnInit() {
    this.activeSlider = false;
    this.document.body.classList.remove('noscroll');
    this.currentSlide = 'Oil Paintings';
    this.currentThumb = 'assets/images/divinemarco.png';
    this.index = 0;
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const number = this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
    if (number > 100) {
      this.opaque = true;
    } else if (this.opaque && number < 10) {
      this.opaque = false;
    }


  }

  openSocial(id) {
    if (id === 1) {
      window.open('https://twitter.com/ceciliadeltoro', '_blank');
    } else if (id === 2) {
      window.open('https://www.linkedin.com/in/ceciliadeltoro', '_blank');
    }
  }
  scroll(el) {
    el.scrollIntoView();
  }

  subscribe() {
    function validateEmail(email) {
      // tslint:disable-next-line:max-line-length
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    if (this.emailSub.trim().length !== 0) {
      const validated = validateEmail(this.emailSub);
      if (validated) {
        const body = {
          'email': this.emailSub
        };
        this._api.createSubscriber(body).subscribe((data) => {
          console.log(data);
          this.snack.open('Subscribed to Cecilia Del Toro News', 'OK', { duration: 4000 });
          this.emailSub = '';
        }, (err) => {
          console.log(err);
          this.snack.open(err.statusText, 'OK', { duration: 2000 });
        });
      } else {
        this.snack.open('Enter a valid email', 'OK', {duration: 4000});
      }
    } else {
      this.snack.open('Empty fields', 'OK', { duration: 4000 });
    }

  }

  openSideNav() {
    this.renderer.addClass(this.sidenav.nativeElement, 'open');
    this.renderer.removeClass(this.list.nativeElement, 'paused');
    this.document.body.classList.add('noscroll');
  }
  closeSideNav() {
    console.log(this.sidenav.nativeElement);
    this.renderer.removeClass(this.sidenav.nativeElement, 'open');
    this.document.body.classList.remove('noscroll');
    this.list.nativeElement.forEach(element => {
      element.classList.remove('appear');
    });
    this.renderer.addClass(this.list.nativeElement, 'paused');
    this.list.nativeElement.forEach(element => {
      element.classList.add('appear');
    });
  }

  changerImage() {

  }

}
