import { Component, OnInit, Renderer2, ViewChild, ElementRef, HostListener, Inject } from '@angular/core';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
import { EmmiterColorService } from '../emmiter-color.service';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/platform-browser';
import { WINDOW } from '../windows.service';
import { NgProgress } from 'ngx-progressbar';
import { ApiService } from '../services/api.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-biography',
  templateUrl: './biography.component.html',
  styleUrls: ['./biography.component.sass']
})
export class BiographyComponent implements OnInit {


  opaque: boolean;

  _album: any;

  @ViewChild('sidenav') sidenav: ElementRef;
  @ViewChild('list') list: ElementRef;
  faBars = faBars;
  faTimes = faTimes;
  faChevronCircleDown = faChevronCircleDown;
  childOneActive = this._data.currentChildOne.subscribe((data) => this.childOneActive = data);
  childTwoActive = this._data.currentChildTwo.subscribe((data) => this.childTwoActive = data);
  childThreeActive = this._data.currentChildThree.subscribe((data) => this.childThreeActive = data);
  childFourthActive = this._data.currentChildFourth.subscribe((data) => this.childFourthActive = data);
  content: any;
  editorContent: any;
  constructor(
    private _data: EmmiterColorService,
    private renderer: Renderer2,
    public translate: TranslateService,
    private progress: NgProgress,
    private snack: MatSnackBar,
    private _api: ApiService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window
  ) { }


  ngOnInit() {
    this.progress.start();
    this.document.body.classList.remove('noscroll');
    this._api.getRenderBiographyAsGuest().subscribe((doc) => {
      this.content = doc;
      this.content = this.content.body;
      this.editorContent = this.content;
      this.progress.done();
    }, (err) => {
      this.snack.open('Error open biography ', 'OK', { duration: 4000 });
      this.progress.done();
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const number = this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
    if (number > 30) {
      this.opaque = true;
    } else if (this.opaque && number < 10) {
      this.opaque = false;
    }


  }
  translatePage(event) {
    // console.log(event.target.innerHTML);
    if (event.target.innerHTML === 'SPANISH') {
      this.translate.use('es');
    } else {
      this.translate.use('en');
    }
  }



  openSideNav() {
    this.renderer.addClass(this.sidenav.nativeElement, 'open');
    this.renderer.removeClass(this.list.nativeElement, 'paused');
    this.document.body.classList.add('noscroll');
  }
  closeSideNav() {
    console.log(this.sidenav.nativeElement);
    this.renderer.removeClass(this.sidenav.nativeElement, 'open');
    this.document.body.classList.remove('noscroll');
    this.list.nativeElement.forEach(element => {
      element.classList.remove('appear');
    });
    this.renderer.addClass(this.list.nativeElement, 'paused');
    this.list.nativeElement.forEach(element => {
      element.classList.add('appear');
    });

  }
}
