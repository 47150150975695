import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/free-solid-svg-icons';
import {EmmiterColorService} from '../emmiter-color.service';

@Component({
  selector: 'app-bio-second',
  templateUrl: './bio-second.component.html',
  styleUrls: ['./bio-second.component.sass']
})
export class BioSecondComponent implements OnInit {
  faChevronCircleDown = faChevronCircleDown;
  faChevronCircleUp = faChevronCircleUp;
  @Input() componentActive;
  @Output() colorChanged = new EventEmitter();

  constructor(private _data: EmmiterColorService) { }

  ngOnInit() {
    this._data.changeChildTwo(true);
  }

}
