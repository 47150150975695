import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgProgress } from 'ngx-progressbar';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import { Router } from '@angular/router';
@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.sass']
})
export class SalesComponent implements OnInit {
  @ViewChild('block') blocker: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource;
  Posts: any;
  displayedColumns: string[];
  constructor(
    private _api: ApiService,
    private snack: MatSnackBar,
    private progress: NgProgress,
    private render: Renderer2,
    private router: Router,
    private spinner: NgxSpinnerService,
  ) { }
  // this.render.addClass(this.blocker.nativeElement, 'active');
  ngOnInit() {
    this.renderSales();
    this.displayedColumns = ['Category', 'Post', 'Action'];
  }
  renderSales() {
    this.spinner.show();
    this._api.showAllPost().subscribe((posts) => {
       this.Posts = posts;
       this.dataSource = new MatTableDataSource(this.Posts);
       this.dataSource.paginator = this.paginator;
       setTimeout(() => {
        this.spinner.hide();
       }, 1000);
    }, (err) => {
    });
  }
  show(id) {
    this.router.navigate(['/admin/panel/sales-detail', id]);
  }

}
