import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, Renderer2, HostListener, Inject } from '@angular/core';
import { faBars, faTimes, faPlus, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { EmmiterColorService } from '../emmiter-color.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as snap from 'snapsvg-cjs';
import * as $ from 'jquery';
import { ApiService } from '../services/api.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { DOCUMENT } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

declare var Snap: any;
declare var mina: any;


const BASE_URL_IMAGE = environment.apiUrl;
@Component({
  selector: 'app-gallery-slider',
  templateUrl: './gallery-slider.component.html',
  styleUrls: ['./gallery-slider.component.sass']
})

export class GallerySliderComponent implements OnInit, OnDestroy {
  faBars = faBars;
  faTimes = faTimes;
  faPlus = faPlus;
  faShoppingCart = faShoppingCart;
  topic;

  modeSlider: boolean;
  @ViewChild('gallery') gallery: ElementRef;
  @ViewChild('overlay') overlay: ElementRef;
  @ViewChild('fullback') fullback: ElementRef;
  @ViewChild('sidenav') sidenav: ElementRef;
  @ViewChild('list') list: ElementRef;


  urlimage = BASE_URL_IMAGE;
  id: any;
  Atributes: any;
  NameSelected: any;
  urlSelected: any;

  currentPostId: any;
  currentPostName: any;
  currentPostDesc: any;
  currentPostBody: any;

  categoryName: any;
  categoryDesc: any;


  idSelected: any;
  Posts: any;


  constructor(
    private _data: EmmiterColorService,
    private router: Router,
    private renderer: Renderer2,
    private spinner: NgxSpinnerService,
    private _api: ApiService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document) {
  }
  ngOnInit() {
    this.modeSlider = false;
    this.spinner.show();
    this.document.body.classList.remove('noscroll');
    this.document.body.classList.add('noscroll');
    this.route.params.subscribe((param) => {
      this.id = +param['id'];
      this._api.getCategoryAsGuest(this.id).subscribe((category) => {
        this.categoryName = category[0]['name'];
        this.categoryDesc = category[0]['description'];
        this._api.getAllPostsAsGuest(this.id).subscribe((posts) => {
          this.Posts = posts;
          if (this.Posts.length < 4) {
            this.document.body.classList.remove('noscroll');
            this.router.navigateByUrl('/gallery/slides/cardmode/' + this.id);
          } else {
            setTimeout(() => {
              this.main();
              this.document.body.classList.remove('noscroll');
              this.spinner.hide();
            }, 500);
          }
        });
      });
    });
    // if (localStorage.getItem('current') !== undefined && localStorage.getItem('current') !== null) {
    //   this.topic = localStorage.getItem('current');
    // } else {
    //   this._data.currentTopic.subscribe((data) => this.topic = data);
    //   localStorage.setItem('current', this.topic);
    // }
  }

  translatePage(event) {
    // console.log(event.target.innerHTML);
    if (event.target.innerHTML === 'SPANISH') {
      this.translate.use('es');
    } else {
      this.translate.use('en');
    }
  }

  main() {
    const duration = ($('.no-csstransitions').length > 0) ? 0 : 300;
    function svgClippedSlider(element) {
      this.element = element;
      this.slidesGallery = this.element.find('.gallery').children('li');
      this.slidesCaption = this.element.find('.caption').children('li');
      this.slidesNumber = this.slidesGallery.length;
      this.selectedSlide = this.slidesGallery.filter('.selected').index();
      this.arrowNext = this.element.find('.navigation').find('.next');
      this.arrowPrev = this.element.find('.navigation').find('.prev');

      this.visibleSlidePath = this.element.data('selected');
      this.lateralSlidePath = this.element.data('lateral');
      this.bindEvents();
    }

    svgClippedSlider.prototype.bindEvents = function () {
      const self = this;
      this.slidesGallery.on('click', function (event) {
        if (!$(this).hasClass('selected')) {
          const newSlideIndex = ($(this).hasClass('left'))
            ? self.showPrevSlide(self.selectedSlide - 1)
            : self.showNextSlide(self.selectedSlide + 1);
        }
      });
    };

    svgClippedSlider.prototype.showPrevSlide = function (index) {
      const self = this;
      this.selectedSlide = index;
      this.slidesGallery.eq(index + 1).add(this.slidesCaption.eq(index + 1)).removeClass('selected').addClass('right');
      this.slidesGallery.eq(index).add(this.slidesCaption.eq(index)).removeClass('left').addClass('selected');

      snap('#cd-morphing-path-' + (index + 1)).animate({ 'd': self.visibleSlidePath }, duration, mina.easeinout);
      snap('#cd-morphing-path-' + (index + 2)).animate({ 'd': self.lateralSlidePath }, duration, mina.easeinout);

      // tslint:disable-next-line:max-line-length
      if (index - 1 >= 0) { this.slidesGallery.eq(index - 1).add(this.slidesCaption.eq(index - 1)).removeClass('left-hide').addClass('left'); }
      if (index + 2 < this.slidesNumber) { this.slidesGallery.eq(index + 2).add(this.slidesCaption.eq(index + 2)).removeClass('right'); }

      // tslint:disable-next-line:no-unused-expression
      (index <= 0) && this.element.addClass('prev-hidden');
      this.element.removeClass('next-hidden');

      this.arrowPrev.addClass('active').on('webkitAnimationEnd oanimationend msAnimationEnd animationend', function () {
        self.arrowPrev.removeClass('active');
      });
    };

    svgClippedSlider.prototype.showNextSlide = function (index) {
      const self = this;
      this.selectedSlide = index;
      this.slidesGallery.eq(index - 1).add(this.slidesCaption.eq(index - 1)).removeClass('selected').addClass('left');
      this.slidesGallery.eq(index).add(this.slidesCaption.eq(index)).removeClass('right').addClass('selected');

      snap('#cd-morphing-path-' + (index + 1)).animate({ 'd': self.visibleSlidePath }, duration, mina.easeinout);
      snap('#cd-morphing-path-' + (index)).animate({ 'd': self.lateralSlidePath }, duration, mina.easeinout);

      // tslint:disable-next-line:max-line-length
      if (index - 2 >= 0) { this.slidesGallery.eq(index - 2).add(this.slidesCaption.eq(index - 2)).removeClass('left').addClass('left-hide'); }
      if (index + 1 < this.slidesNumber) { this.slidesGallery.eq(index + 1).add(this.slidesCaption.eq(index + 1)).addClass('right'); }

      // tslint:disable-next-line:no-unused-expression
      (index + 1 >= this.slidesNumber) && this.element.addClass('next-hidden');
      this.element.removeClass('prev-hidden');

      this.arrowNext.addClass('active').on('webkitAnimationEnd oanimationend msAnimationEnd animationend', function () {
        self.arrowNext.removeClass('active');
      });
    };

    $('.cd-svg-clipped-slider').each(function () {
      new svgClippedSlider($(this));
    });
  }
  closeOverlay() {
    this.renderer.addClass(this.overlay.nativeElement, 'hide');
    this.renderer.removeClass(this.overlay.nativeElement, 'appear');
    this.renderer.removeClass(this.fullback.nativeElement, 'blurred');
    this.currentPostBody = '';
    this.currentPostDesc = '';
    this.currentPostName = '';
    this.urlSelected = '';
  }
  toogleCurrent() {
    const parent = this.gallery.nativeElement;
    const elem = parent.getElementsByClassName('selected')[0];
    // this.urlSelected = elem.getElementsByTagName('image')[0].href.baseVal;
    this.idSelected = elem.getElementsByTagName('input')[0].name;

    this._api.getPostAsGuest(this.id, this.idSelected).subscribe((data ) => {

    this.currentPostBody = data[0]['body'];
    this.currentPostName = data[0]['name'];
    this.currentPostDesc = data[0]['desc'];
    this.currentPostId = this.idSelected;
    this.urlSelected = BASE_URL_IMAGE + data[0]['attachments'][0].file.hd.url;
    this.renderer.removeClass(this.overlay.nativeElement, 'hide');
    this.renderer.addClass(this.fullback.nativeElement, 'blurred');
    this.renderer.addClass(this.overlay.nativeElement, 'appear');
    }, (err) => {});

  }

  buyPost() {
    this.router.navigateByUrl('/post/buy/' + this.currentPostId + '/' + this.id );
  }

  switch() {
    console.log('hola');
    this.router.navigateByUrl('/gallery/slides/cardmode/' + this.id);
  }

  toogleCurrent2(event) {
    const parent = this.gallery.nativeElement;
    const elem = parent.getElementsByClassName('selected')[0];

    // this.urlSelected = elem.getElementsByTagName('image')[0].href.baseVal;
    this.idSelected = elem.getElementsByTagName('input')[0].name;

    this._api.getPostAsGuest(this.id, this.idSelected).subscribe((data ) => {

    this.currentPostBody = data[0]['body'];
    this.currentPostName = data[0]['name'];
    this.currentPostDesc = data[0]['desc'];
    this.currentPostId = this.idSelected;
    this.urlSelected = BASE_URL_IMAGE + data[0]['attachments'][0].file.hd.url;
    this.renderer.removeClass(this.overlay.nativeElement, 'hide');
    this.renderer.addClass(this.fullback.nativeElement, 'blurred');
    this.renderer.addClass(this.overlay.nativeElement, 'appear');
    }, (err) => {});

  }

  openSideNav() {
    this.renderer.addClass(this.sidenav.nativeElement, 'open');
    this.renderer.removeClass(this.list.nativeElement, 'paused');
    this.document.body.classList.add('noscroll');
  }
  closeSideNav() {
    this.document.body.classList.remove('noscroll');
    this.renderer.removeClass(this.sidenav.nativeElement, 'open');
    this.list.nativeElement.forEach(element => {
      element.classList.remove('appear');
    });
    this.renderer.addClass(this.list.nativeElement, 'paused');
    this.list.nativeElement.forEach(element => {
      element.classList.add('appear');
    });

  }
  ngOnDestroy(): void {
    localStorage.removeItem('current');
  }





}
