import { Component, OnInit, ViewChild, ElementRef, Renderer2, Inject } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { NgProgress } from 'ngx-progressbar';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

export interface DialogData {
  elemid: string;
  status: boolean;
}

export interface DialogData2 {
  categoryName: string;
  categoryDesc: string;
  selectedFiles: any[];
  // oldFiles: any;
  thumb: any;
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const BASE_URL = environment.apiUrl;



@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.sass']
})

export class CategoriesComponent implements OnInit {

  @ViewChild('inputfile') input: ElementRef;
  @ViewChild('categoria') input2: ElementRef;
  @ViewChild('description') input3: ElementRef;
  @ViewChild('block') blocker: ElementRef;

  displayedColumns: string[];
  dataSource: any;
  disabled: boolean;
  constructor(
    private api: ApiService,
    private render: Renderer2,
    public dialog: MatDialog,
    public progressService: NgProgress,
    private spinner: NgxSpinnerService,
    private _data: DataService,
    private router: Router,
    private snack: MatSnackBar) { }

  files: any;
  hasCategories: any;
  Categories: any;


  elemid: any;


  selectedFile: any[];
  catName: string;
  catDesc: string;


  ELEMENT_DATA;

  ngOnInit() {
    this.files = 'Select File(s)';
    this.disabled = false;
    this.requestCategories();
  }


  showCategory(id) {
    this.router.navigate(['/admin/panel/category-detail/', id]);
  }
  requestCategories() {
    this.spinner.show();
    this.api.listCategories().subscribe((data) => {
      this.Categories = data;
      this.dataSource = data;
      this.displayedColumns = ['id', 'name', 'action'];
      this.spinner.hide();
      const info = JSON.stringify(this.Categories);
      if (this.Categories.length === 0) {
        this.hasCategories = false;
      } else {
        this.hasCategories = true;
      }
    }, (err) => {
    });

  }
  upload(name, description, files) {
    this.render.addClass(this.blocker.nativeElement, 'active');
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);

    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        const element = files[key];
        formData.append('attachments_files[]', element);
      }
    }
    this.progressService.start();
    this.progressService.set(0.1);
    this.api.uploadImages(formData, response => {
      if (response) {
        this.progressService.set(0.2);
        this.api.listCategories().subscribe((data) => {
          this.Categories = data;
          this.dataSource = data;
          this.render.removeClass(this.blocker.nativeElement, 'active');
          this.snack.open('Created succesfully ', 'OK', { duration: 2000 });
          this.progressService.done();
          if (this.Categories.length === 0) {
            this.hasCategories = false;
          } else {
            this.hasCategories = true;
          }
        });
      } else {
        this.snack.open('Error ' + response.name, 'OK', {
          duration: 4000
        });
      }
    });
  }

  update(name, description, files, id) {
    this.progressService.start();
    this.render.addClass(this.blocker.nativeElement, 'active');
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        const element = files[key];
        formData.append('attachments_files[]', element);
      }
    }
    this.progressService.set(0.1);
    if (files && files.length !== 0) {
      this.api.getCategory(id).subscribe((category) => {
        const idAttachment = category['attachments'][0].id;
        this.api.deleteAttachment(idAttachment).subscribe((done) => {
          this.api.updateCategory(id, formData, response => {
            if (response) {
              this.api.listCategories().subscribe((data) => {
                this.Categories = data;
                this.dataSource = data;
                this.displayedColumns = ['id', 'name', 'action'];
                if (this.Categories.length === 0) {
                  this.hasCategories = false;
                } else {
                  this.hasCategories = true;
                }
                this.render.removeClass(this.blocker.nativeElement, 'active');
                this.progressService.done();
              }, (err) => {
                this.snack.open('Error ' + err.statusText, 'OK', {
                  duration: 4000
                });
              });
            } else {
              this.progressService.done();
              this.render.removeClass(this.blocker.nativeElement, 'active');
              this.snack.open('Error ' + response.name, 'OK', {
                duration: 4000
              });
            }
          });
        });
      });
    } else {
      this.render.addClass(this.blocker.nativeElement, 'active');
      this.api.updateCategory(id, formData, response => {
        if (response) {
          this.api.listCategories().subscribe((data) => {
            this.Categories = data;
            this.dataSource = data;
            this.displayedColumns = ['id', 'name', 'action'];
            this.progressService.done();
            if (this.Categories.length === 0) {
              this.hasCategories = false;
            } else {
              this.hasCategories = true;
            }
            this.render.removeClass(this.blocker.nativeElement, 'active');
          }, (err) => {
            this.render.removeClass(this.blocker.nativeElement, 'active');
            this.snack.open('Error ' + err.statusText, 'OK', {
              duration: 4000
            });
          });
        } else { }
      });
    }

  }
  openDialog(id, status): void {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      width: '250px',
      data: { status: status }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.progressService.start();
        let body;
        if (!status) {
          body = {
            'status': true
          };
        } else {
          body = {
            'status': false
          };
        }
        this.render.addClass(this.blocker.nativeElement, 'active');
        this.api.disableCategory(id, body).subscribe((data) => {
          this.progressService.set(0.8);
          this.api.listCategories().subscribe((data2) => {
            this.progressService.done();
            this.Categories = data2;
            this.dataSource = this.Categories;
            this.render.removeClass(this.blocker.nativeElement, 'active');
            if (this.Categories.length === 0) {
              this.hasCategories = false;
            } else {
              this.hasCategories = true;
            }
          });
        }, (err) => {
          this.snack.open('Error ' + err, 'OK', {
            duration: 4000
          });
        });
      }
    });
  }

  openDialog2(): void {
    const dialogRef = this.dialog.open(DialogCreateComponent, {
      width: '700px',
      minHeight: '500px',
      data: { elemid: 'id' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.name.trim().length !== 0 && result.description.trim().length !== 0
        && result.files !== undefined) {
        if (result.files.length > 0) {
          this.upload(result.name, result.description, result.files);
        } else {
          this.snack.open('Empty fields', 'OK', { duration: 2000 });
        }
      } else {
        this.snack.open('Empty fields', 'OK', { duration: 2000 });
      }
    });
  }

  openDialog3(id): void {
    this.api.getCategory(id).subscribe((data) => {
      let thumbnail;
      if (data['attachments'].length !== 0) {
        if (data['attachments'][0].file.thumb.url !== undefined && data['attachments'][0].file.thumb.url) {
          thumbnail = BASE_URL + data['attachments'][0].file.thumb.url;
        } else {
          thumbnail = 'assets/images/question-mar.png';
        }
      } else {
        thumbnail = 'assets/images/question-mar.png';
      }
      const dialogRef = this.dialog.open(DialogUpdateComponent, {
        width: '750px',
        minHeight: '500',
        data: { categoryName: data['name'], categoryDesc: data['description'], selectedFiles: [], thumb: thumbnail }
      });
      dialogRef.afterClosed().subscribe(result => {
        this.update(result.name, result.description, result.files, id);
      });
    });

  }




}




// Modals
@Component({
  selector: 'app-dialog-confirmation',
  templateUrl: 'dialog-confirm.html',
  styleUrls: ['./categories.component.sass']
})
export class DialogConfirmComponent {

  messageEnabled: boolean;
  status: boolean;
  constructor(
    public dialogRef: MatDialogRef<DialogConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.status = data.status;
    if (this.status) {
      this.messageEnabled = true;
    } else {
      this.messageEnabled = false;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onClick() {
    this.dialogRef.close(true);
  }
}


@Component({
  selector: 'app-dialog-create',
  templateUrl: 'dialog-create.html',
  styleUrls: ['./categories.component.sass']
})
export class DialogCreateComponent {
  files: string;

  categoryName: string;
  categoryDesc: string;

  selectedFiles: any[];
  constructor(
    public dialogRef: MatDialogRef<DialogCreateComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.files = 'Select a File';
    this.selectedFiles = [];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClick() {
    this.dialogRef.close({ files: this.selectedFiles, name: this.categoryName, description: this.categoryDesc });
  }
  onFileChanged(event) {
    this.selectedFiles = event.target.files;
    console.log(this.selectedFiles);
    this.files = this.selectedFiles.length + ' Files Selected';
  }

}



@Component({
  selector: 'app-dialog-update',
  templateUrl: 'dialog-update.html',
  styleUrls: ['./categories.component.sass']
})
export class DialogUpdateComponent {
  files: string;

  categoryName: string;
  categoryDesc: string;
  oldFiles: string;
  selectedFiles: any[];
  categoryThumb: any;
  constructor(
    public dialogRef: MatDialogRef<DialogUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData2
  ) {
    this.categoryName = data.categoryName;
    this.categoryDesc = data.categoryDesc;
    this.selectedFiles = data.selectedFiles;

    this.files = 'Change image';
    this.categoryThumb = data.thumb;
    // this.oldFiles = data.oldFiles;

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClick() {
       this.dialogRef.close({ files: this.selectedFiles, name: this.categoryName, description: this.categoryDesc });
  }
  onFileChanged(event) {
    this.selectedFiles = event.target.files;
    console.log(this.selectedFiles);
    this.files = this.selectedFiles.length + ' Files Selected';
  }

}
