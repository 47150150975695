import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LightboxModule } from 'ngx-lightbox';
import { AppComponent } from './app.component';
import { HomePageComponent } from './home-page/home-page.component';
import { AppRoutingModule } from './app-routing.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BiographyComponent } from './biography/biography.component';
import { GalleryComponent } from './gallery/gallery.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ContactComponent } from './contact/contact.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import {MatListModule} from '@angular/material/list';
import { GallerySliderComponent } from './gallery-slider/gallery-slider.component';
import { BioFirstComponent } from './bio-first/bio-first.component';
import { BioSecondComponent } from './bio-second/bio-second.component';
import { BioThirdComponent } from './bio-third/bio-third.component';
import { BioFourthComponent } from './bio-fourth/bio-fourth.component';
import { WINDOW_PROVIDERS } from './windows.service';
import { LoginComponent } from './admin/login/login.component';
import { PanelComponent } from './admin/panel/panel.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { CategoriesComponent, DialogConfirmComponent,
   DialogCreateComponent, DialogUpdateComponent } from './admin/panel/categories/categories.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import {MatTableModule} from '@angular/material/table';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { InboxComponent, DialogShowComponent, DialogDeleteMessageComponent,
   DialogCreateMessage2Component } from './admin/panel/inbox/inbox.component';
import { PublicationsComponent } from './publications/publications.component';
import { MenuComponent } from './admin/panel/menu/menu.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgProgressModule } from 'ngx-progressbar';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule, MatDialogConfig, MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {OverlayContainer} from '@angular/cdk/overlay';
import { CategoriesDetailComponent, DialogCreatePostComponent,
   DialogUpdate2Component,
   DialogDeletePostComponent,
   DialogUpdatePostComponent} from './admin/panel/categories-detail/categories-detail.component';
import {MatIconModule} from '@angular/material/icon';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { PostDetailComponent, DialogDeletePost2Component,
   DialogUpdate3PostComponent } from './admin/panel/post-detail/post-detail.component';
import { SubscribersComponent, DialogDeleteComponent, DialogCreateMessageComponent } from './admin/panel/subscribers/subscribers.component';
import { RentComponent, DialogRentComponent } from './rent/rent.component';
import { BlogComponent, DialogCreatePublicationComponent,
   DialogDeletePublicationComponent, DialogUpdatePublicationComponent } from './admin/panel/blog/blog.component';
import { BlogDetailComponent,
  DialogDeletePubAttachComponent, DialogUpdateBlogDComponent } from './admin/panel/blog-detail/blog-detail.component';
import { GallerySliderToggleComponent } from './gallery-slider-toggle/gallery-slider-toggle.component';
import { PublicationDetailComponent } from './publication-detail/publication-detail.component';
import { ScrollToModule } from 'ng2-scroll-to-el';
import { SalesComponent } from './admin/panel/sales/sales.component';
import { MatPaginatorModule } from '@angular/material';
import { SalesDetailComponent, DialogShowCommentsComponent,
  DialogConfirmSaleComponent } from './admin/panel/sales-detail/sales-detail.component';
  import { NgxImageZoomModule } from 'ngx-image-zoom';
  import { SliderModule } from 'angular-image-slider';

import {UICarouselModule} from 'ui-carousel';
import { PublicationMediaComponent } from './publication-media/publication-media.component';
import { BiographyControlComponent } from './admin/panel/biography-control/biography-control.component';


import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    BiographyComponent,
    GalleryComponent,
    ContactComponent,
    GallerySliderComponent,
    BioFirstComponent,
    BioSecondComponent,
    BioThirdComponent,
    BioFourthComponent,
    LoginComponent,
    PanelComponent,
    CategoriesComponent,
    InboxComponent,
    PublicationsComponent,
    MenuComponent,
    DialogConfirmComponent,
    DialogCreateComponent,
    DialogUpdateComponent,
    CategoriesDetailComponent,
    DialogCreatePostComponent,
    DialogUpdate2Component,
    DialogDeletePostComponent,
    DialogUpdatePostComponent,
    PostDetailComponent,
    DialogDeletePost2Component,
    DialogUpdate3PostComponent,
    SubscribersComponent,
    DialogDeleteComponent,
    DialogCreateMessageComponent,
    RentComponent,
    BlogComponent,
    DialogCreatePublicationComponent,
    DialogDeletePublicationComponent,
    DialogUpdatePublicationComponent,
    BlogDetailComponent,
    GallerySliderToggleComponent,
    PublicationDetailComponent,
    DialogShowComponent,
    DialogDeletePubAttachComponent,
    DialogDeleteMessageComponent,
    DialogCreateMessage2Component,
    DialogUpdateBlogDComponent,
    DialogShowCommentsComponent,
    DialogRentComponent,
    DialogConfirmSaleComponent,
    SalesComponent,
    SalesDetailComponent,
    PublicationMediaComponent,
    BiographyControlComponent,
  ],
  entryComponents: [
    DialogConfirmComponent,
    DialogCreateComponent,
    DialogUpdateComponent,
    DialogCreatePostComponent,
    DialogUpdate2Component,
    DialogDeletePostComponent,
    DialogUpdatePostComponent,
    DialogDeletePost2Component,
    DialogDeleteComponent,
    DialogUpdate3PostComponent,
    DialogCreateMessageComponent,
    DialogCreatePublicationComponent,
    DialogDeletePublicationComponent,
    DialogDeletePubAttachComponent,
    DialogUpdatePublicationComponent,
    DialogShowComponent,
    DialogShowCommentsComponent,
    DialogDeleteMessageComponent,
    DialogConfirmSaleComponent,
    DialogCreateMessage2Component,
    DialogUpdateBlogDComponent,
    DialogRentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatButtonModule,
    NgxSpinnerModule,
    MatListModule,
    UICarouselModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    HttpClientModule,
    CurrencyMaskModule,
    MatToolbarModule,
    MatIconModule,
    MatDialogModule,
    MatPaginatorModule,
    SliderModule,
    BrowserAnimationsModule,
    NgxImageZoomModule.forRoot(),
    LightboxModule,
    MatSnackBarModule,
    ScrollToModule.forRoot(),
    MatTableModule,
    FormsModule,
    MatSidenavModule,
    FontAwesomeModule,
    NgProgressModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],

  providers: [
    WINDOW_PROVIDERS,
    MatDialogConfig,
    // {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
