import { Component, OnInit, Renderer2, HostListener, Inject, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { environment } from 'src/environments/environment';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DOCUMENT } from '@angular/platform-browser';
import { WINDOW } from '../windows.service';
import { Location } from '@angular/common';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Lightbox } from 'ngx-lightbox';
import { NgProgress } from 'ngx-progressbar';

export interface DialogData {
  attachments: any;
}

const BASE_IMAGE_URL = environment.apiImageUrl;
@Component({
  selector: 'app-rent',
  templateUrl: './rent.component.html',
  styleUrls: ['./rent.component.sass']
})
export class RentComponent implements OnInit {

  opaque: boolean;
  faBars = faBars;
  faTimes = faTimes;
  idpost: any;
  idcategory: any;
  hasMoreImage: boolean;
  postname: any;
  postbody: any;
  postbrief: any;
  posturl: any;
  postprice: any;
  comments: any;
  // Para el formulario de envío (Solicitud de compra.)
  firstName: any;
  lastName: any;
  conctNumber: any;
  emailAddress: any;
  commentss: any;


  _album: Array<any> = [];

  buying: boolean;

  attachments: any;


  @ViewChild('list') list: ElementRef;
  @ViewChild('block') blocker: ElementRef;
  @ViewChild('sidenav') sidenav: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _api: ApiService,
    private renderer: Renderer2,
    private _location: Location,
    public snack: MatSnackBar,
    public translate: TranslateService,
    private progress: NgProgress,
    private _lightbox: Lightbox,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window
  ) { }

  ngOnInit() {
    this.hasMoreImage = false;
    this.opaque = false;
    this.document.body.classList.remove('noscroll');
    this.buying = false;
    this.route.params.subscribe((param) => {
      this.idpost = +param['id'];
      this.idcategory = +param['idcat'];
      this._api.getPostAsGuest(this.idcategory, this.idpost).subscribe((data) => {
        this.postname = data[0]['name'];
        this.postbody = data[0]['body'];
        this.postbrief = data[0]['description'];
        this.attachments = data[0]['attachments'];
        this.attachments.forEach(element => {
          const album = {
            src: BASE_IMAGE_URL + element.file.hd.url,
            thumb: BASE_IMAGE_URL + element.file.thumb.url,
          };
          this._album.push(album);
        });
        this.posturl = BASE_IMAGE_URL + data[0]['attachments'][0].file.hd.url;
        // this.postprice = data[0]['price'];

      });
    });
  }


  translatePage(event) {
    // console.log(event.target.innerHTML);
    if (event.target.innerHTML === 'SPANISH') {
      this.translate.use('es');
    } else {
      this.translate.use('en');
    }
  }


  open(index: number): void {
    this._lightbox.open(this._album, index, {
      showImageNumberLabel: true,
      alwaysShowNavOnTouchDevices: true,
      disableScrolling: true,
      centerVertically: true
    });
  }
  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
  back() {
    this._location.back();
  }


  @HostListener('window:scroll', [])
  onWindowScroll() {
    const number = this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
    if (number > 100) {
      this.opaque = true;
    } else if (this.opaque && number < 10) {
      this.opaque = false;
    }


  }


  openSideNav() {
    this.renderer.addClass(this.sidenav.nativeElement, 'open');
    this.renderer.removeClass(this.list.nativeElement, 'paused');
    this.document.body.classList.add('noscroll');
  }
  closeSideNav() {
    this.renderer.removeClass(this.sidenav.nativeElement, 'open');
    this.document.body.classList.remove('noscroll');
    this.list.nativeElement.forEach(element => {
      element.classList.remove('appear');
    });
    this.renderer.addClass(this.list.nativeElement, 'paused');
    this.list.nativeElement.forEach(element => {
      element.classList.add('appear');
    });
  }

  sendToCecilia() {
    this.progress.start();
    this.renderer.addClass(this.blocker.nativeElement, 'active');
    function validateEmail(email) {
      // tslint:disable-next-line:max-line-length
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    if (this.buying) {
      if (this.firstName.trim().length !== 0
        && this.lastName.trim().length !== 0
        && this.emailAddress.trim().length !== 0) {
        if (validateEmail(this.emailAddress)) {
          const body = {
            'first_name': this.firstName,
            'last_name': this.lastName,
            'phone': this.conctNumber,
            'email': this.emailAddress,
            'coments': this.commentss
          };
          this._api.createSaleSolicitude(this.idpost, body).subscribe((done) => {
            this.firstName = '';
            this.lastName = '';
            this.conctNumber = '';
            this.emailAddress = '';
            this.commentss = '';
            this.renderer.removeClass(this.blocker.nativeElement, 'active');
            this.progress.done();
            this.snack.open('Message Sended', 'OK', {duration: 4000});
          }, (error) => {
            this.snack.open(error.statusText, 'OK', { duration: 4000 });
          });
        } else {
          this.snack.open('Enter a valid email', 'OK', { duration: 4000 });
        }
      } else {
        this.snack.open('Error, empty fields', 'OK', { duration: 4000 });
      }
      // Accion a realizar cuando se vaya a comprar.
    }
  }
  buy() {
    this.buying = true;
    this.commentss = '';
    this.firstName = '';
    this.lastName = '';
    this.emailAddress = '';
    this.conctNumber = '';
  }

  notbuy() {
    this.buying = false;
  }

  openAll() {
    const dialogRef = this.dialog.open(DialogRentComponent, {
      minWidth: '700px',
      minHeight: '700px',
      data: { attachments: this.attachments }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }


}




@Component({
  selector: 'app-dialog-update',
  templateUrl: 'dialog-show.html',
  styleUrls: ['./rent.component.sass']
})
export class DialogRentComponent {
  files: string;
  BASE_URL_IMAGE = BASE_IMAGE_URL;
  attachments: any;
  constructor(
    public dialogRef: MatDialogRef<DialogRentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.attachments = data.attachments;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onClick() {
  }

}
